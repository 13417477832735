import useDisplay from "../../../hooks/useDisplay";

function MainBGLayout02(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: isMobile ? "flex-start" : "center",
          justifyContent: "center",
          padding: isMobile ? "0px 20px 0px 20px" : "0px 30px 0px 30px",

          width: "100%",
          minHeight: isMobile ? 440 : 400,
          backgroundImage: isMobile ? "url(/assets/images/main_bg02_m.png)" : "url(/assets/images/main_bg02.png)",
          backgroundSize: "100% 100%",

          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default MainBGLayout02;
