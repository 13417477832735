import useDisplay from "../../../hooks/useDisplay";

function MainBGLayout02(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent:"center",
          width: isMobile? "100%":"100%",
          minHeight: isMobile ? 540 : 448,
          backgroundColor : "#00B3CE",

          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default MainBGLayout02;
