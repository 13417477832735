import useDisplay from "../../../hooks/useDisplay";

function MainBGLayout(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: isMobile ? 570 : 930,
          backgroundImage: isMobile ? "url(/assets/images/main_bg_m.png)" : "url(/assets/images/main_bg.png)",
          backgroundSize: "100% 100%",

          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default MainBGLayout;
