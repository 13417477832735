import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import PretendardText from "../../atoms/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import MainBtn from "../../atoms/button/mainBtn";
import TextBtn from "../../atoms/button/textBtn";
import Input from "../../atoms/input/input";
// import GrayBtn from "../../atoms/button/grayBtn";

const VideoReviewDetailModal = ({ setIsModal, data }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const [isPlay, setIsPlay] = useState(false);

  return (
    <ModalWrapper
      style={{
        background: isMobile ? "rgba(0, 0, 0, 0.85)" : "rgba(0, 0, 0, 0.75)",
      }}
    >
      <ModalPosition>
        <ModalContain
          style={{
            width: isMobile ? "100vw" : "1010px",
            padding: isMobile ? "0px 20px" : "",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <TextBtn
              onClick={() => {
                setIsModal(false);
              }}
              style={{}}
            >
              <img
                src="/assets/icons/ic_close.svg"
                alt="Close Icon"
                style={{
                  width: 24,
                  height: 24,
                  marginBottom: isMobile ? "15px" : "",
                }}
              />
            </TextBtn>
          </div>
          <div>
            <div
              style={{
                width: isMobile ? "" : "976px",
                height: isMobile ? "" : "549px",
                borderRadius: isMobile ? "16px" : "20px",
                position: "relative",
              }}
            >
              <video
                id="video"
                preload="metadata"
                src={`${data.fileUrl}#t=0.5`}
                controls={isPlay}
                style={{ width: "100%", objectFit: "cover", borderRadius: 20 }}
              >
                <source
                  src={data.fileUrl ? data.fileUrl : ""}
                  type="video/mp4"
                />
              </video>

              {!isPlay ? (
                <img
                  src="/assets/icons/play.png"
                  alt="Play Icon"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 60,
                    height: 60,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (document.querySelector("#video")) {
                      document.querySelector("#video").play();
                      setIsPlay(true);
                    }
                  }}
                />
              ) : null}
            </div>

            <div
              style={{
                marginTop: "20px",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 20,
                  lineHeight: "32px",
                  color: "#FFFFFF",
                  fontWeight: 700,
                }}
              >
                {data.title}
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#FFFFFF",
                  fontWeight: 400,
                  marginTop: "10px",
                }}
              >
                {data.content}
              </PretendardText>
            </div>
          </div>
        </ModalContain>
      </ModalPosition>
    </ModalWrapper>
  );
};

export default VideoReviewDetailModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  overflow: hidden;
  touch-action: none;
`;

const ModalPosition = styled.div.attrs((props) => {})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
`;

const ModalContain = styled.div.attrs((props) => {})`
  width: 1010px;
  height: auto;

  overflow: auto;
  position: relative;
`;

const ModalScrollLayoutWrapper = styled.div.attrs((props) => {})`
  overflow-y: auto;
  height: 82vh;

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(32, 32, 38); /* 스크롤바 색상 */
    border-radius: 2px; /* 스크롤바 둥근 테두리 */
  }

  ::-webkit-scrollbar-track {
    background: rgba(32, 32, 38, 0.1); /*스크롤바 뒷 배경 색상*/
  }
`;
