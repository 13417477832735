import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/pretendardText";
import { useNavigate, useHistory } from "react-router-dom";
import useDisplay from "../../hooks/useDisplay";

function Pagenation({
  route,
  totalPages,
  currentPage,
  rangeSize,
  first,
  last,
  style,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();

  const pageList = useMemo(() => {
    const pageGroup = Math.ceil(currentPage / rangeSize)

    let firstNumber = (pageGroup * rangeSize) - (rangeSize - 1)
    if (firstNumber <= 0) {
      firstNumber = 1
    }

    let lastNumber = pageGroup * rangeSize
    if (lastNumber > totalPages) {
      lastNumber = totalPages
    }

    const pageList = []
    for (let i = firstNumber; i <= lastNumber; i++) {
      pageList.push(i)
    }

    return pageList
  }, [totalPages, currentPage, rangeSize]);

  return (
    <>
      {
        pageList.length > 0 ?
          <>
            <PagenationWrapper style={{
              width: isMobile ? '240px' : '390px',
              ...style
            }}>
              <a
                style={{
                  cursor: "pointer",
                  transform: first ? '' : 'rotate( 180deg )'
                }}
                onClick={() => {
                  if (first) {
                    return
                  }

                  history.push({
                    pathname: route + `/${Number(currentPage) - 1}`,
                  });                  
                }}
              >
                <img
                  src={first ? "/assets/icons/arrow-left.svg" : "/assets/icons/arrow-right-active.svg"}
                  alt={first ? "Arrow Left Icon" : "Arrow Right Icon"}
                />
              </a>
              <PagenationNumWrapper>
                {
                  pageList.map((item, index) => {
                    return (
                      <PagenationNum
                        key={index}
                        style={{
                          color: Number(currentPage) === item ? "#262C31" : "#B8BFC4",
                        }}
                        onClick={() => {
                          history.push({
                            pathname: route + `/${item}`,
                          });
                        }}
                      >
                        {item}
                      </PagenationNum>
                    )
                  })
                }
              </PagenationNumWrapper>
              <a
                style={{
                  cursor: "pointer",
                  transform: last ? 'rotate( 180deg )' : ''
                }}
                onClick={() => {
                  if (last) {
                    return
                  }
                  history.push({
                    pathname: route + `/${Number(currentPage) + 1}`,
                  });                  
                }}
              >
                <img
                  src={last ? "/assets/icons/arrow-left.svg" : "/assets/icons/arrow-right-active.svg"}
                  alt={last ? "Arrow Left Icon" : "Arrow Right Icon"}
                />
              </a>
            </PagenationWrapper>
          </>
          : null
      }
    </>
  );
}

export default Pagenation;

const PagenationWrapper = styled.div.attrs((props) => { })`
  margin-top: 40px;  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const PagenationNum = styled(PretendardText).attrs((props) => { })`
  display: flex;
  font-weight: 700;
  line-height: 28px;

  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #b8bfc4;
`;

const PagenationNumWrapper = styled.div.attrs((props) => { })`
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;
`;
