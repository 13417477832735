import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import MainLayout from "../atoms/layouts/mainLayout";
import MainContainer from "../atoms/layouts/mainContainer";
import PretendardText from "../atoms/pretendardText";
import MainBtn from "../atoms/button/mainBtn";
import MainBtnNotDisabled from "../atoms/button/mainBtnNotDisabled";
import { route } from "../../routes/route";
import HeaderText from "./headerText";

const Header = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [isFixed, setIsFixed] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [pathName, setPathName] = useState(null);

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);

    if (scrollPosition > 60) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }

    return () => {
      window.removeEventListener("scroll", updateScroll); //clean up
    };
  }, [scrollPosition]);

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative",
        background: "transparent",
        position: isFixed ? "fixed" : "relative",
        background: isFixed ? "#151515" : "transparent",
        zIndex: 999,
      }}
    >
      <MainLayout
        style={{
          height: 80,
        }}
      >
        <MainContainer
          style={{
            width: "93%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            minWidth: isFixed ? 1165 : 1200,
          }}
        >
          <div>
            <img
              onClick={() => {
                history.push({
                  pathname: route.main,
                });
              }}
              src={props.main || isFixed ? "/assets/icons/logo.svg" : "/assets/icons/logo_black.svg"}
              alt="Edu at Talk Logo Image"
              style={{
                width: 120,
                height: 35,
                cursor: "pointer",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <HeaderText isFixed={isFixed} text={"에듀엣톡 소개"} isMain={props.main} path={route.eduatalk_intro} />
            <HeaderText isFixed={isFixed} text={"프로그램 소개"} isMain={props.main} path={route.program_intro} />
            <HeaderText isFixed={isFixed} text={"진로적성검사"} isMain={props.main} path={route.career_test} />
            {/* <HeaderText
              isFixed={isFixed}
              text={"고입"}
              isMain={props.main}
              path={route.high_entrance}
            /> */}
            <HeaderText isFixed={isFixed} text={"대입"} isMain={props.main} path={route.college_entrance} />
            <HeaderText isFixed={isFixed} text={"후기"} isMain={props.main} path={route.review_brilliant} usePagination={true} />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 140,
                  height: 50,
                  marginRight: 8,
                  background: "#00B3CE",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  history.push({
                    pathname: route.service_payment,
                  });
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#fff",
                    fontWeight: 700,
                  }}
                >
                  서비스 결제
                </PretendardText>
              </div>
              <img
                src={props.main || isFixed ? "/assets/icons/google_store.svg" : "/assets/icons/google_store_b.svg"}
                alt="Google Play Icon"
                style={{
                  width: 140,
                  height: 50,
                  marginRight: 8,
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("https://play.google.com/store/apps/details?id=com.eduatalk.eduatalk&hl=ko-KR");
                }}
              />
              <img
                src={props.main || isFixed ? "/assets/icons/apple_store.svg" : "/assets/icons/apple_store_b.svg"}
                alt="Apple Store Icon"
                style={{
                  width: 140,
                  height: 50,
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("https://apps.apple.com/kr/app/%EC%97%90%EB%93%80%EC%97%A3%ED%86%A1-%EC%A3%BC/id6472698636");
                }}
              />
            </div>
          </div>
        </MainContainer>
      </MainLayout>
    </div>
  );
};

export default Header;
