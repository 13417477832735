import api from "./api.js";

export default class ReviewApi { 
  static async Get(data) {
    return await api.get(`web/review`, data);
  }
  static async GetById(reviewId) {
    return await api.get(`web/review/${reviewId}`);
  } 
}
