import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../components/organisms/header";
import HeaderM from "../components/organisms/headerM";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import Input from "../components/atoms/input/input";
import MainLayout from "../components/atoms/layouts/mainLayout";
import { route } from "../routes/route";
import HighEntranceBGLayout from "../components/atoms/layouts/highEntranceBGLayout";
import MainContainer from "../components/atoms/layouts/mainContainer";
import useDisplay from "../hooks/useDisplay";
import { is } from "date-fns/locale";
import styled from "styled-components";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";

function HighEntrance(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();
  const [position, setPosition] = useState(0);

  function onScroll() {
    setPosition(window.scrollY);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 100,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  고입
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: isMobile ? 32 : 60,
                  lineHeight: isMobile ? "44.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                고입준비
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                경험과 역량을 갖춘 전문가화 대비하는 자기주도전형 대비 프로그램
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 30,
                }}
              >
                막막한 자기소개서와 면접! 학교생활기록부 분석을 참고하여
                자기소개서 각 항목에 들어갈 주제와 방향을 제시하고 지속적인
                소통을 통해 학생의 비전을 이끌어냅니다.
              </PretendardText>

              <img
                src={
                  isMobile
                    ? "/assets/images/highEntrance_top01_m.png"
                    : "/assets/images/highEntrance_top01.png"
                }
                alt="High School Entrance"
                style={{
                  width: isMobile ? "90vw" : "100%",
                  marginBottom: 100,
                }}
              />

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 30,
                }}
              >
                프로그램 소개
              </PretendardText>

              <div
                style={{
                  width: isMobile ? "90vw" : "100%",
                }}
              >
                <div
                  style={{
                    overflowX: "auto",
                  }}
                >
                  <table
                    style={{
                      width: 1200,
                      borderTop: "1px solid #DDDDDD",
                      borderBottom: "1px solid #DDDDDD",
                      marginBottom: isMobile ? 30 : 0,
                    }}
                  >
                    <tr
                      style={{
                        height: 50,
                        backgroundColor: "#F7F7F7",
                      }}
                    >
                      <th
                        rowSpan={2}
                        style={{
                          borderRight: "1px solid #DDDDDD",
                          width: 220,
                        }}
                      >
                        {" "}
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          대상
                        </PretendardText>
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          width: 1080,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          예비 고1 (현 중학교 3학년)
                        </PretendardText>
                      </th>
                    </tr>

                    <tr
                      style={{
                        height: 50,
                        backgroundColor: "#F7F7F7",
                        borderTop: "1px solid #DDDDDD",
                      }}
                    >
                      <th
                        style={{
                          borderRight: "1px solid #DDDDDD",
                          width: 540,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          자율형 사립고 (광역, 전국)
                        </PretendardText>
                      </th>

                      <th
                        style={{
                          width: 540,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          특목고 (외고, 국제고)
                        </PretendardText>
                      </th>
                    </tr>
                    <tr
                      style={{
                        height: 50,
                        borderTop: "1px solid #DDDDDD",
                      }}
                    >
                      <th
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          시기
                        </PretendardText>
                      </th>
                      <th colSpan={2} style={{}}>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          8월 말 ~ 12월 말
                        </PretendardText>
                      </th>
                    </tr>
                    <tr
                      style={{
                        height: 144,
                        borderTop: "1px solid #DDDDDD",
                      }}
                    >
                      <th
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          서류
                        </PretendardText>
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          paddingLeft: 20,
                        }}
                      >
                        <ul
                          style={{
                            paddingLeft: 20,
                            marginBottom: 0,
                          }}
                        >
                          <li>
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              프로그램 사전준비
                              <br />- 학교생활기록부 (3-2/국영수과)
                              <br />- 신청서
                            </PretendardText>
                          </li>
                        </ul>
                      </th>
                    </tr>

                    <tr
                      style={{
                        height: 180,
                        borderTop: "1px solid #DDDDDD",
                      }}
                    >
                      <th
                        style={{
                          borderRight: "1px solid #DDDDDD",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#222222",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          회차
                        </PretendardText>
                      </th>
                      <th
                        colSpan={2}
                        style={{
                          paddingLeft: 20,
                        }}
                      >
                        <ul
                          style={{
                            paddingLeft: 20,
                            marginBottom: 0,
                          }}
                        >
                          <li>
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              자기소개서 작성
                              <br />- 작성 4회 + 첨삭 2회
                            </PretendardText>
                          </li>

                          <li>
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              면접대비
                              <br />- 학교별 면접 준비 4회
                              <br />- 실전 모의면접 (원서접수 후)
                            </PretendardText>
                          </li>
                        </ul>
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <HighEntranceBGLayout>
          <div
            style={{
              width: isMobile ? "90vw" : 1200,
              display: "flex",
              position: isMobile ? "static" : "relative",
              flexDirection: "column",
              alignItems: "start",
              minHeight: 1520,
            }}
          >
            {isMobile ? (
              position > 1010 ? (
                <TextUPAnimation
                  style={{
                    fontSize: 24,
                    lineHeight: "38.4px",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    marginTop: 100,
                    marginBottom: 10,
                  }}
                >
                  프로그램 진행
                </TextUPAnimation>
              ) : null
            ) : position > 780 ? (
              <TextUPAnimation
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#FFFFFF",
                  fontWeight: 700,
                  marginTop: 100,
                  marginBottom: 10,
                }}
              >
                프로그램 진행
              </TextUPAnimation>
            ) : null}

            {isMobile ? (
              position > 1020 ? (
                <TextUPAnimation
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#FFFFFF",
                    fontWeight: 400,
                    marginBottom: isMobile ? 30 : 10,
                  }}
                >
                  자사고(광역,전국) / 특목고(외고, 국제고)
                </TextUPAnimation>
              ) : null
            ) : position > 780 ? (
              <TextUPAnimation
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#FFFFFF",
                  fontWeight: 400,
                  marginBottom: isMobile ? 30 : 10,
                }}
              >
                자사고(광역,전국) / 특목고(외고, 국제고)
              </TextUPAnimation>
            ) : null}

            {isMobile ? (
              position > 1200 ? (
                <ImgAnimation
                  src={
                    isMobile
                      ? "/assets/images/highEntrance_bottom01_m.png"
                      : "/assets/images/highEntrance_bottom01.png"
                  }
                  alt="High School Entrance"
                  style={{
                    position: isMobile ? "static" : "absolute",
                    left: isMobile ? "auto" : 0,
                    top: isMobile ? "auto" : 214,
                    width: isMobile ? "90vw" : 616,
                    marginBottom: isMobile ? 30 : 0,
                  }}
                />
              ) : null
            ) : position > 800 ? (
              <ImgAnimation
                src={
                  isMobile
                    ? "/assets/images/highEntrance_bottom01_m.png"
                    : "/assets/images/highEntrance_bottom01.png"
                }
                alt="High School Entrance"
                style={{
                  position: isMobile ? "static" : "absolute",
                  left: isMobile ? "auto" : 0,
                  top: isMobile ? "auto" : 214,
                  width: isMobile ? "90vw" : 616,
                  marginBottom: isMobile ? 30 : 0,
                }}
              />
            ) : null}
            {position > 800 ? (
              <ImgRightAnimation
                src={
                  isMobile
                    ? "/assets/images/highEntrance_01_m.png"
                    : "/assets/images/highEntrance_01.png"
                }
                alt="High School Entrance"
                style={{
                  display: isMobile ? "none" : "inline",
                  position: "absolute",
                  left: 642,
                  top: 174,
                  width: 124,
                  height: 168,
                  objectFit: "contain",
                }}
              />
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: isMobile ? "static" : "absolute",
                left: isMobile ? "auto" : 642,
                top: isMobile ? "auto" : 392,
                marginBottom: isMobile ? 50 : 0,
              }}
            >
              {isMobile ? (
                position > 1350 ? (
                  <TextLeftAnimation
                    style={{
                      fontSize: 20,
                      lineHeight: "32px",
                      color: "#FFFFFF",
                      fontWeight: 700,
                      marginBottom: 10,
                    }}
                  >
                    오리엔테이션
                  </TextLeftAnimation>
                ) : null
              ) : position > 800 ? (
                <TextRightAnimation
                  style={{
                    fontSize: 20,
                    lineHeight: "32px",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  오리엔테이션
                </TextRightAnimation>
              ) : null}

              {isMobile ? (
                position > 1400 ? (
                  <TextLeftAnimation
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#E6E6E6",
                      fontWeight: 400,
                    }}
                  >
                    7월 중 모집, 8월 OT
                    <br />
                    입학전형, 자기소개서 작성 유의사항,{" "}
                    {isMobile ? <div /> : null}
                    합격생 자기소개서 예시,
                    {isMobile ? null : <div />}
                    좋은 면접자세, 입시대비 {isMobile ? <div /> : null}일정 등
                    종합안내
                    <br />
                  </TextLeftAnimation>
                ) : null
              ) : position > 800 ? (
                <TextRightAnimation
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#E6E6E6",
                    fontWeight: 400,
                  }}
                >
                  7월 중 모집, 8월 OT
                  <br />
                  입학전형, 자기소개서 작성 유의사항,{" "}
                  {isMobile ? <div /> : null}
                  합격생 자기소개서 예시,
                  {isMobile ? null : <div />}
                  좋은 면접자세, 입시대비 {isMobile ? <div /> : null}일정 등
                  종합안내
                  <br />
                </TextRightAnimation>
              ) : null}
            </div>

            {isMobile ? (
              position > 1600 ? (
                <ImgAnimation
                  src={
                    isMobile
                      ? "/assets/images/highEntrance_bottom02_m.png"
                      : "/assets/images/highEntrance_bottom02.png"
                  }
                  alt="High School Entrance"
                  style={{
                    position: isMobile ? "static" : "absolute",
                    right: isMobile ? "auto" : 0,
                    top: isMobile ? "auto" : 661,
                    width: isMobile ? "90vw" : 616,
                    marginBottom: isMobile ? 30 : 0,
                  }}
                />
              ) : null
            ) : position > 1450 ? (
              <ImgAnimation
                src={
                  isMobile
                    ? "/assets/images/highEntrance_bottom02_m.png"
                    : "/assets/images/highEntrance_bottom02.png"
                }
                alt="High School Entrance"
                style={{
                  position: isMobile ? "static" : "absolute",
                  right: isMobile ? "auto" : 0,
                  top: isMobile ? "auto" : 661,
                  width: isMobile ? "90vw" : 616,
                  marginBottom: isMobile ? 30 : 0,
                }}
              />
            ) : null}

            {position > 1450 ? (
              <ImgLeftAnimation
                src={
                  isMobile
                    ? "/assets/images/highEntrance_02_m.png"
                    : "/assets/images/highEntrance_02.png"
                }
                alt="High School Entrance"
                style={{
                  display: isMobile ? "none" : "inline",
                  position: "absolute",
                  left: 92,
                  top: 656,
                  width: 147,
                  height: 168,
                  objectFit: "contain",
                }}
              />
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: isMobile ? "static" : "absolute",
                left: isMobile ? "auto" : 92,
                top: isMobile ? "auto" : 862,
                width: isMobile ? "287" : "auto",
                alignSelf: "end",
                marginBottom: isMobile ? 50 : 0,
              }}
            >
              {isMobile ? (
                position > 1800 ? (
                  <TextRightAnimation
                    style={{
                      fontSize: 20,
                      lineHeight: "32px",
                      color: "#FFFFFF",
                      fontWeight: 700,
                      marginBottom: 10,
                    }}
                  >
                    자기소개서 작성
                  </TextRightAnimation>
                ) : null
              ) : position > 1510 ? (
                <TextLeftAnimation
                  style={{
                    fontSize: 20,
                    lineHeight: "32px",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  자기소개서 작성
                </TextLeftAnimation>
              ) : null}

              <ul style={{ paddingLeft: 28, color: "#E6E6E6" }}>
                {isMobile ? (
                  position > 1810 ? (
                    <LiRightAnimation>
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#E6E6E6",
                          fontWeight: 400,
                          marginBottom: 10,
                        }}
                      >
                        시기 8월~12월
                      </PretendardText>
                    </LiRightAnimation>
                  ) : null
                ) : position > 1520 ? (
                  <LiLeftAnimation>
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#E6E6E6",
                        fontWeight: 400,
                        marginBottom: 10,
                      }}
                    >
                      시기 8월~12월
                    </PretendardText>
                  </LiLeftAnimation>
                ) : null}

                {isMobile ? (
                  position > 1820 ? (
                    <LiRightAnimation>
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#E6E6E6",
                          fontWeight: 400,
                          marginBottom: 10,
                        }}
                      >
                        개요작성 : 학생부 분석 참고하여 자기소개서{" "}
                        {isMobile ? <div /> : null}각 항목 들어갈 주제 선정
                      </PretendardText>
                    </LiRightAnimation>
                  ) : null
                ) : position > 1520 ? (
                  <LiLeftAnimation>
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#E6E6E6",
                        fontWeight: 400,
                        marginBottom: 10,
                      }}
                    >
                      개요작성 : 학생부 분석 참고하여 자기소개서{" "}
                      {isMobile ? <div /> : null}각 항목 들어갈 주제 선정
                    </PretendardText>
                  </LiLeftAnimation>
                ) : null}

                {isMobile ? (
                  position > 1830 ? (
                    <LiRightAnimation>
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#E6E6E6",
                          fontWeight: 400,
                          marginBottom: 10,
                        }}
                      >
                        지원동기 / 진로계획 작성
                      </PretendardText>
                    </LiRightAnimation>
                  ) : null
                ) : position > 1530 ? (
                  <LiLeftAnimation>
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#E6E6E6",
                        fontWeight: 400,
                        marginBottom: 10,
                      }}
                    >
                      지원동기 / 진로계획 작성
                    </PretendardText>
                  </LiLeftAnimation>
                ) : null}

                {isMobile ? (
                  position > 1840 ? (
                    <LiRightAnimation>
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#E6E6E6",
                          fontWeight: 400,
                          marginBottom: 10,
                        }}
                      >
                        인성영역 작성
                      </PretendardText>
                    </LiRightAnimation>
                  ) : null
                ) : position > 1540 ? (
                  <LiLeftAnimation>
                    <PretendardText
                      style={{
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#E6E6E6",
                        fontWeight: 400,
                        marginBottom: 10,
                      }}
                    >
                      인성영역 작성
                    </PretendardText>
                  </LiLeftAnimation>
                ) : null}
              </ul>
            </div>

            {isMobile ? (
              position > 2110 ? (
                <ImgAnimation
                  src={
                    isMobile
                      ? "/assets/images/highEntrance_bottom03_m.png"
                      : "/assets/images/highEntrance_bottom03.png"
                  }
                  alt="High School Entrance"
                  style={{
                    position: isMobile ? "static" : "absolute",
                    left: isMobile ? "auto" : 0,
                    top: isMobile ? "auto" : 1108,
                    width: isMobile ? "90vw" : 616,
                    marginBottom: isMobile ? 30 : 0,
                  }}
                />
              ) : null
            ) : position > 1890 ? (
              <ImgAnimation
                src={
                  isMobile
                    ? "/assets/images/highEntrance_bottom03_m.png"
                    : "/assets/images/highEntrance_bottom03.png"
                }
                alt="High School Entrance"
                style={{
                  position: isMobile ? "static" : "absolute",
                  left: isMobile ? "auto" : 0,
                  top: isMobile ? "auto" : 1108,
                  width: isMobile ? "90vw" : 616,
                  marginBottom: isMobile ? 30 : 0,
                }}
              />
            ) : null}

            {position > 1890 ? (
              <ImgRightAnimation
                src={
                  isMobile
                    ? "/assets/images/highEntrance_03_m.png"
                    : "/assets/images/highEntrance_03.png"
                }
                alt="High School Entrance"
                style={{
                  display: isMobile ? "none" : "inline",
                  position: "absolute",
                  left: 642,
                  top: 1108,
                  width: 151,
                  height: 168,
                  objectFit: "contain",
                }}
              />
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: isMobile ? "static" : "absolute",
                left: isMobile ? "auto" : 642,
                top: isMobile ? "auto" : 1334,
                marginBottom: isMobile ? 100 : 0,
              }}
            >
              {isMobile ? (
                position > 2220 ? (
                  <TextLeftAnimation
                    style={{
                      fontSize: 20,
                      lineHeight: "32px",
                      color: "#FFFFFF",
                      fontWeight: 700,
                      marginBottom: 10,
                    }}
                  >
                    면접준비
                  </TextLeftAnimation>
                ) : null
              ) : position > 1980 ? (
                <TextRightAnimation
                  style={{
                    fontSize: 20,
                    lineHeight: "32px",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  면접준비
                </TextRightAnimation>
              ) : null}

              {isMobile ? (
                position > 2230 ? (
                  <TextLeftAnimation
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#E6E6E6",
                      fontWeight: 400,
                      marginBottom: 5,
                    }}
                  >
                    실전 모의 면접 : 공통 / 개별 질문
                  </TextLeftAnimation>
                ) : null
              ) : position > 1990 ? (
                <TextRightAnimation
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#E6E6E6",
                    fontWeight: 400,
                    marginBottom: 5,
                  }}
                >
                  실전 모의 면접 : 공통 / 개별 질문
                </TextRightAnimation>
              ) : null}

              {isMobile ? (
                position > 2240 ? (
                  <TextLeftAnimation
                    style={{
                      fontSize: 14,
                      lineHeight: "22.4px",
                      color: "#9B9B9B",
                      fontWeight: 400,
                    }}
                  >
                    * 1차 전형 불합격시 모의 면접 비용은 환불 됩니다.
                  </TextLeftAnimation>
                ) : null
              ) : position > 2000 ? (
                <TextRightAnimation
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#9B9B9B",
                    fontWeight: 400,
                  }}
                >
                  * 1차 전형 불합격시 모의 면접 비용은 환불 됩니다.
                </TextRightAnimation>
              ) : null}
            </div>
          </div>
        </HighEntranceBGLayout>

        <Footer />

        <FooterBtnGroup />
      </MainColorLayout>
    </>
  );
}

export default HighEntrance;

const ImgAnimation = styled.img`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInUp 1s;
`;

const ImgLeftAnimation = styled.img`
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  animation: fadeInLeft 1s;
`;

const ImgRightAnimation = styled.img`
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInRight 1s;
`;

const TextUPAnimation = styled(PretendardText)`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInUp 1s;
`;

const TextLeftAnimation = styled(PretendardText)`
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  animation: fadeInLeft 1s;
`;

const TextRightAnimation = styled(PretendardText)`
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInRight 1s;
`;

const LiLeftAnimation = styled.li`
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  animation: fadeInLeft 1s;
`;

const LiRightAnimation = styled.li`
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInRight 1s;
`;
