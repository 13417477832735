import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import MainLayout from "../atoms/layouts/mainLayout";
import MainContainer from "../atoms/layouts/mainContainer";
import PretendardText from "../atoms/pretendardText";
import MainBtn from "../atoms/button/mainBtn";
import { route } from "../../routes/route";
import useDisplay from "../../hooks/useDisplay";
import BaseApi from "../../api/base-api";

const Footer = (props) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const history = useHistory();
  const location = useLocation();

  // init
  const getData = async () => {
    try {
      const response = await BaseApi.Get();

      setData({
        ...response.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [data, setData] = useState({
    businessName: "",
    representative: "",
    businessNumber: "",
    phoneNumber: "",
    address: "",
  });

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <MainLayout
        style={{
          width: "100%",
          padding: isMobile ? "20px" : "11.5px 0px",
          borderTop: "1px solid #DDDDDD",
          borderBottom: "1px solid #DDDDDD",
        }}
      >
        <MainContainer>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: isMobile ? 5 : 0,
              }}
            >
              <PretendardText
                onClick={() => {
                  history.push({
                    pathname: route.policy,
                  });
                }}
                style={{
                  fontSize: 14,
                  lineHeight: "22.4px",
                  color:
                    location.pathname === route.policy ? "#555555" : "#9B9B9B",
                  fontWeight:
                    location.pathname === route.policy ? "700" : "400",
                  cursor: "pointer",
                }}
              >
                개인정보취급방침
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "22.4px",
                  color: "#DDDDDD",
                  fontWeight: 400,
                  margin: "0px 5px",
                }}
              >
                |
              </PretendardText>
              <PretendardText
                onClick={() => {
                  history.push({
                    pathname: route.term,
                  });
                }}
                style={{
                  fontSize: 14,
                  lineHeight: "22.4px",
                  color:
                    location.pathname === route.term ? "#555555" : "#9B9B9B",
                  fontWeight: location.pathname === route.term ? "700" : "400",
                  cursor: "pointer",
                }}
              >
                이용약관
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "22.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginRight: 5,
                }}
              >
                고객센터 {data.phoneNumber}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "22.4px",
                  color: "#9B9B9B",
                  fontWeight: 400,
                }}
              >
                운영시간: 평일 09:00~18:00
              </PretendardText>
            </div>
          </div>
        </MainContainer>
      </MainLayout>
      <MainLayout
        style={{
          width: "100%",
          padding: isMobile ? "20px" : "16.5px 0px",
          background: "#F7F7F7",
        }}
      >
        <MainContainer>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
            }}
          >
            <img
              src="/assets/icons/flogo.png"
              alt="Edu at Talk Logo Image"
              style={{
                width: 80,
                height: 30,
                marginRight: isMobile ? 0 : 20,
                marginBottom: isMobile ? 20 : 0,
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: isMobile ? "25px" : "20.8px",
                  color: "#9B9B9B",
                  fontWeight: 400,
                }}
              >
                대표이사 : {data.representative} {isMobile ? <br /> : null}
                주소 : {data.address} {isMobile ? <br /> : null}
                사업자등록번호 : {data.businessNumber}{" "}
                {isMobile ? <br /> : null}
                대표번호: {data.phoneNumber} {isMobile ? <br /> : null}
                E-mail : eduatalk23@naver.com {isMobile ? <br /> : null}
                통신판매업 신고번호 : 제2024-서울송파-2528호
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 13,
                  lineHeight: "20.8px",
                  color: "#C6C6C6",
                  fontWeight: 400,
                  marginTop: 5,
                }}
              >
                Copyright © Edu at Talk All Rights Reserved.
              </PretendardText>
            </div>
          </div>
        </MainContainer>
      </MainLayout>
    </div>
  );
};

export default Footer;
