import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../components/organisms/header";
import HeaderM from "../components/organisms/headerM";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import Input from "../components/atoms/input/input";
import MainLayout from "../components/atoms/layouts/mainLayout";
import { route } from "../routes/route";
import MainContainer from "../components/atoms/layouts/mainContainer";
import useDisplay from "../hooks/useDisplay";
import ProgramIntroBGLayout from "../components/atoms/layouts/programIntroBGLayout";
import ProgramIntroBGLayout02 from "../components/atoms/layouts/programIntroBGLayout02";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";
function ProgramIntro(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 30,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  프로그램 소개
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: isMobile ? 32 : 60,
                  lineHeight: isMobile ? "44.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                프로그램 소개
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                생기부레벨업
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#555555",
                  fontWeight: 400,
                }}
              >
                학업역량을 높이는 보고서 작성과 진로에 따른 선택과목,
                {isMobile ? <br /> : null} 정규과정 내 비교과활동의 균형잡힌
                학생부를 완성할수{isMobile ? <br /> : null} 있도록
                {isMobile ? null : <br />}
                학생들이 보다 효과적이고 효율적으로 학생부
                {isMobile ? <br /> : null} 종합전형을 준비할수 있도록 돕는
                입시교육{isMobile ? <br /> : null} 프로그램입니다.
              </PretendardText>
            </div>
          </MainContainer>
        </MainLayout>
        <ProgramIntroBGLayout>
          <div
            style={{
              width: isMobile ? "90vw" : 1200,
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                width: "100%",
                justifyContent: isMobile ? "start" : "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: isMobile ? 80 : 100,
                  marginBottom: isMobile ? 30 : 0,
                }}
              >
                <img
                  src="/assets/icons/question.png"
                  alt="Question Icon"
                  style={{
                    width: 25,
                    height: 25,
                    marginBottom: 10,
                  }}
                />

                <PretendardText
                  style={{
                    fontSize: 24,
                    lineHeight: "38.4px",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  다른 학생부종합관리 프로그램과
                  <br />
                  다른 점은 무엇인가요?
                </PretendardText>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: isMobile ? 0 : 135,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  ‘에듀엣톡 생기부레벨업’는 학생 스스로 학교 안에서{" "}
                  {isMobile ? <br /> : null}수행할 수 있는 활동을 찾고
                  <br />
                  계획할 수 있도록 돕는 역할에 충실하고자 합니다.
                </PretendardText>

                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#E6E6E6",
                    fontWeight: 400,
                  }}
                >
                  학생부종합전형에서 성과를 얻기 위해서는{" "}
                  {isMobile ? <br /> : null}모든 활동이 학교 안에서 이루어져야
                  합니다. <br />
                  학교 선생님들이나 다른 학생들과의 소통과 교류 속에서{" "}
                  {isMobile ? <br /> : null}학습하고 탐구하는 활동만이{" "}
                  {isMobile ? null : <br />}
                  학생부종합전형에서 평가를 {isMobile ? <br /> : null}받을 수
                  있으며 이것을 잘 수행할 수 있도록 학생이 <br />
                  계획한 활동을 잘 수행하고 있는지, 올바른 방향성으로{" "}
                  {isMobile ? <br /> : null}나아가고 있는지 지속적으로{" "}
                  {isMobile ? null : <br />}
                  확인함으로써 {isMobile ? <br /> : null}학생의 주도적인 성장과
                  발전을 이끌어 나갑니다.
                </PretendardText>
              </div>
            </div>
          </div>
        </ProgramIntroBGLayout>

        <ProgramIntroBGLayout02>
          <div
            style={{
              width: isMobile ? "100%" : 1200,
              height: isMobile ? "auto" : 448,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "strat" : "end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: isMobile ? 60 : 100,
                marginBottom: isMobile ? 30 : 0,
                marginLeft: isMobile ? "20px" : 0,
                alignSelf: isMobile ? "start" : "start",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#FFFFFF",
                  fontWeight: 700,
                }}
              >
                에듀엣톡만의 생기부 관리와 <br />
                레포트를 확인해보세요!
              </PretendardText>
            </div>

            <img
              src={
                isMobile
                  ? "/assets/images/programIntro_bg02_m.png"
                  : "/assets/images/programIntro_bg02.png"
              }
              alt="Life Record Image"
              style={{
                width: isMobile ? "94vw" : 776,
                alignSelf: isMobile ? "end" : "auto",
                height: isMobile ? "363px" : "",
              }}
            />
          </div>
        </ProgramIntroBGLayout02>

        <MainLayout>
          <MainContainer>
            <div
              style={{ width: isMobile ? "89vw" : "100%", margin: "100px 0" }}
            >
              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 30,
                }}
              >
                프로그램 소개
              </PretendardText>
              <div
                style={{
                  overflowX: "auto",
                  width: "100%",
                }}
              >
                <img
                  src={"/assets/images/programintro_table.png"}
                  alt="프로그램 소개"
                  style={{
                    maxWidth: "1200px", // 이미지 너비 고정
                    whiteSpace: "nowrap",
                    marginBottom: "7px",
                  }}
                />
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />

        <FooterBtnGroup />
      </MainColorLayout>
    </>
  );
}

export default ProgramIntro;
