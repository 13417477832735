import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import Input from "../components/atoms/input/input";
import MainLayout from "../components/atoms/layouts/mainLayout";
import { route } from "../routes/route";
import MainContainer from "../components/atoms/layouts/mainContainer";
import TermsApi from "../api/terms-api";
import HeaderM from "../components/organisms/headerM";
import useDisplay from "../hooks/useDisplay";
import styled from "styled-components";

function Term(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // init
  const getData = async () => {
    try {
      const response = await TermsApi.GetById(5);

      setData({
        ...response.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [data, setData] = useState({});

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 175,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  이용약관
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: isMobile ? 32 : 60,
                  lineHeight: isMobile ? "44.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                이용약관
              </PretendardText>

              <div
                style={{
                  width: "100%",
                  minHeight: "100vh",
                }}
              >
                <TextAreaWrapper
                  style={{
                    width: "100%",
                    height: "100%",
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#555555",
                    fontWeight: 400,
                    border: "none",
                    resize: "none",
                  }}
                  value={data.content}
                />
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />
      </MainColorLayout>
    </>
  );
}

export default Term;

const TextAreaWrapper = styled.textarea.attrs((props) => {})`
  caret-color: #ffffff;
  :focus {
    outline: none !important;
  }
`;
