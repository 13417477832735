import useDisplay from "../../../hooks/useDisplay";

function MainBGLayout03(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: isMobile ? "0px 20px 0px 20px" : "0px 30px 0px 30px",

          width: "100%",
          minHeight: isMobile ? 300 : 208,
          backgroundImage: isMobile
            ? "url(/assets/images/main_bottom_bg_m.png)"
            : "url(/assets/images/main_bottom_bg.png)",
          backgroundSize: "100% 100%",

          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default MainBGLayout03;
