import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HeaderM from "../components/organisms/headerM";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import Input from "../components/atoms/input/input";
import MainLayout from "../components/atoms/layouts/mainLayout";
import { route } from "../routes/route";
import MainContainer from "../components/atoms/layouts/mainContainer";
import useDisplay from "../hooks/useDisplay";
import ReviewApi from "../api/review-api";
import moment from "moment";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";
function ReviewStudentDetail(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { id } = useParams();

  // init
  const getData = async () => {
    try {
      const response = await ReviewApi.GetById(id);

      setData({
        ...response.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const [data, setData] = useState({
    createdAt: "",
    writerEmail: "",
    title: "",
    content: "",
    originFileName: "",
    serverFileName: "",
    fileUrl: "",
  });

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}
        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 100,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  후기
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: isMobile ? 32 : 60,
                  lineHeight: isMobile ? "44.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                빛나는 후기
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 15,
                }}
              >
                {data.title}
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#9B9B9B",
                  fontWeight: 400,
                  marginBottom: 15,
                }}
              >
                {moment(data.createdAt).format("YYYY.MM.DD")}
              </PretendardText>

              <div
                style={{
                  height: 1,
                  width: "100%",
                  marginTop: 30,
                  marginBottom: 30,
                  backgroundColor: "#DDDDDD",
                }}
              />

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 40,
                }}
              >
                {data.content}
              </PretendardText>
              <img
                src={data.fileUrl}
                alt={data.originFileName}
                style={{
                  width: "100%",
                  marginBottom: 30,
                  alignSelf: "center",
                }}
              />
              <div
                style={{
                  height: 1,
                  width: "100%",
                  marginBottom: 30,
                  backgroundColor: "#DDDDDD",
                }}
              />

              <div
                style={{
                  display: "flex",
                  width: isMobile ? "90vw" : "100%",
                  height: 110,
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: 150,
                    height: 50,
                    flexDirection: "row",
                    borderBottom: "2px solid #353535",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                    }}
                  >
                    목록으로
                  </PretendardText>

                  <img
                    src="/assets//icons/ic_arrow-right.png"
                    alt="Arrow Right Icon"
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </div>
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />

        <FooterBtnGroup />
      </MainColorLayout>
    </>
  );
}

export default ReviewStudentDetail;
