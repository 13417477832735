import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainBtnNotDisabled from "../button/mainBtnNotDisabled";
import PretendardText from "../pretendardText";

function Input(props) {
  return (
    <>
      <div
        style={{
          width: "100%",
          position: "relative",
          ...props.parentStyle,
        }}
      >
        {props.isTitle ? (
          <PretendardText
            style={{
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "25px",
              color: "#FFFFFF",
              marginBottom: 7,
            }}
          >
            {props.title}
          </PretendardText>
        ) : null}

        <div
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <InputWrapper
            value={props.value}
            onChange={props.onChange}
            type={props.type}
            placeholder={props.placeholder}
            style={{
              ...props.style,
            }}
            onKeyDown={props.onKeyDown}
            maxLength={props.maxLength}
            disabled={props.disabled}
            warningMessage={props.warningMessage}
          />
          {props.isRightBtn ? (
            <MainBtnNotDisabled
              onClick={props.rightOnClick}
              style={{
                position: "absolute",
                right: -10,
                background: "#7E53FF",
                width: 65,
                height: 31,
                borderRadius: 2,
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "22px",
                  color: "white",
                  fontWeight: 400,
                }}
              >
                {props.rightBtnText}
              </PretendardText>
            </MainBtnNotDisabled>
          ) : null}
        </div>
        {props.warningMessage ? (
          <PretendardText
            style={{
              fontWeight: 400,
              fontSize: 12,
              lineHeight: "18px",
              color: "#FF3124",
              marginTop: 2,
            }}
          >
            {props.warningMessage}
          </PretendardText>
        ) : null}
      </div>
    </>
  );
}

export default Input;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 51px;
  border-radius: 2px;
  padding: 14px 16px;
  background: #2c2c33;
  border: ${(props) =>
    props.warningMessage ? "1px solid #FF3124" : "1px solid #2c2c33"};

  font-family: "Pretendard";
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  :focus {
    outline: none !important;
    border-color: #7e53ff;
  }

  ::placeholder {
    color: #9897a0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;
