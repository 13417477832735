import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import MainLayout from "../atoms/layouts/mainLayout";
import MainContainer from "../atoms/layouts/mainContainer";
import PretendardText from "../atoms/pretendardText";
import MainBtn from "../atoms/button/mainBtn";
import MainBtnNotDisabled from "../atoms/button/mainBtnNotDisabled";
import { route } from "../../routes/route";

const HeaderM = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [isFixed, setIsFixed] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [pathName, setPathName] = useState(null);
  const [isSideMenu, setIsSideMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);

    if (scrollPosition > 60) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }

    return () => {
      window.removeEventListener("scroll", updateScroll); //clean up
    };
  }, [scrollPosition]);

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        position: isFixed ? "fixed" : "relative",
        background: isFixed ? "#FFFFFF" : "transparent",
        zIndex: 99999,
      }}
    >
      <MainLayout
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: 50,
        }}
      >
        <img
          onClick={() => {
            history.push({
              pathname: route.main,
            });
          }}
          src={
            (pathName?.includes("eduatalk/main") || pathName === "/") &&
            !isFixed
              ? "/assets/icons/logo.svg"
              : "/assets/icons/logo_m.png"
          }
          style={{
            width: 83,
            height: 31,
            cursor: "pointer",
          }}
          alt=""
        />

        <img
          onClick={() => {
            setIsSideMenu(!isSideMenu);
          }}
          src={"/assets/icons/menu.png"}
          alt="Menu Icon"
          style={{
            width: 24,
            height: 24,
            cursor: "pointer",
            filter:
              props.main && !isFixed
                ? "invert(98%) sepia(7%) saturate(411%) hue-rotate(282deg) brightness(119%) contrast(100%)"
                : "none",
          }}
        />
      </MainLayout>

      {isSideMenu ? (
        <Modal>
          <MenuMobile>
            <div
              style={{
                padding: "13px 20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <img
                onClick={() => {
                  history.push({
                    pathname: route.main,
                  });
                }}
                src="/assets/icons/logo_white.svg"
                alt="Edu at Talk Logo Image"
                style={{
                  width: 83,
                  height: 31,
                  cursor: "pointer",
                }}
              />
              <img
                onClick={() => {
                  setIsSideMenu(false);
                }}
                src="/assets/icons/ic_close.png"
                alt="Close Icon"
                style={{
                  width: 24,
                  height: 24,
                  cursor: "pointer",
                }}
              />
            </div>

            <PretendardText
              onClick={() => {
                history.push({
                  pathname: route.eduatalk_intro,
                });
              }}
              style={{
                fontSize: 24,
                lineHeight: "33.6px",
                color: `${
                  pathName?.includes("eduatalk/eduatalk_intro")
                    ? "#00B3CE"
                    : "#ffffff"
                }`,
                fontWeight: 600,
                cursor: "pointer",
                padding: "10px 20px",
              }}
            >
              에듀엣톡 소개
            </PretendardText>
            <PretendardText
              onClick={() => {
                history.push({
                  pathname: route.program_intro,
                });
              }}
              style={{
                fontSize: 24,
                lineHeight: "33.6px",
                color: `${
                  pathName?.includes("eduatalk/program_intro")
                    ? "#00B3CE"
                    : "#ffffff"
                }`,
                fontWeight: 600,
                cursor: "pointer",
                padding: "10px 20px",
              }}
            >
              프로그램 소개
            </PretendardText>
            <PretendardText
              onClick={() => {
                history.push({
                  pathname: route.career_test,
                });
              }}
              style={{
                fontSize: 24,
                lineHeight: "33.6px",
                color: `${
                  pathName?.includes("eduatalk/career_test")
                    ? "#00B3CE"
                    : "#ffffff"
                }`,
                fontWeight: 600,
                cursor: "pointer",
                padding: "10px 20px",
              }}
            >
              진로검사
            </PretendardText>
            {/* <PretendardText
              onClick={() => {
                history.push({
                  pathname: route.high_entrance,
                });
              }}
              style={{
                fontSize: 24,
                lineHeight: "33.6px",
                color: `${
                  pathName?.includes("eduatalk/high_entrance")
                    ? "#00B3CE"
                    : "#ffffff"
                }`,
                fontWeight: 600,
                cursor: "pointer",
                padding: "10px 20px",
              }}
            >
              고입
            </PretendardText> */}
            <PretendardText
              onClick={() => {
                history.push({
                  pathname: route.college_entrance,
                });
              }}
              style={{
                fontSize: 24,
                lineHeight: "33.6px",
                color: `${
                  pathName?.includes("eduatalk/college_entrance")
                    ? "#00B3CE"
                    : "#ffffff"
                }`,
                fontWeight: 600,
                cursor: "pointer",
                padding: "10px 20px",
              }}
            >
              대입
            </PretendardText>
            <PretendardText
              onClick={() => {
                history.push({
                  pathname: route.review_brilliant + `/1`,
                });
              }}
              style={{
                fontSize: 24,
                lineHeight: "33.6px",
                color: `${
                  pathName?.includes("eduatalk/review") ? "#00B3CE" : "#ffffff"
                }`,
                fontWeight: 600,
                cursor: "pointer",
                padding: "10px 20px",
              }}
            >
              후기
            </PretendardText>
            <PretendardText
              onClick={() => {
                history.push({
                  pathname: route.inquiry,
                });
              }}
              style={{
                fontSize: 24,
                lineHeight: "33.6px",
                color: `${
                  pathName?.includes("eduatalk/inquiry") ? "#00B3CE" : "#ffffff"
                }`,
                fontWeight: 600,
                cursor: "pointer",
                padding: "10px 20px",
              }}
            >
              문의
            </PretendardText>

            <div
              style={{
                margin: "20px",
                borderBottom: "1px solid #353535",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.eduatalk.eduatalk&hl=ko-KR"
                    );
                  }}
                  src="/assets/icons/google_store_m.svg"
                  alt="Google Play Icon"
                  style={{
                    width: 60,
                    height: 60,
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                />
                <img
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/kr/app/%EC%97%90%EB%93%80%EC%97%A3%ED%86%A1-%EC%A3%BC/id6472698636"
                    );
                  }}
                  src="/assets/icons/apple_store_m.svg"
                  alt="Apple Store Icon"
                  style={{
                    width: 60,
                    height: 60,
                    cursor: "pointer",
                  }}
                />
              </div>
              <div
                style={{
                  width: 140,
                  height: 50,
                  background: "#00B3CE",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  history.push({
                    pathname: route.service_payment,
                  });
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#fff",
                    fontWeight: 700,
                  }}
                >
                  서비스 결제
                </PretendardText>
              </div>
            </div>
          </MenuMobile>
        </Modal>
      ) : null}
    </div>
  );
};

export default HeaderM;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden !important;
  touch-action: none;
`;

const move = keyframes`
from {
  top: -300px;
}
to {
  top: 0px;
}
`;

const MenuMobile = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  background-color: #1e1e1e;
  border-bottom: 2px solid #00b3ce;
  text-align: left;
  animation-name: ${move};
  animation-duration: 1s;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
