import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import useDisplay from "../../hooks/useDisplay";
import { route } from "../../routes/route";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FooterBtnGroup = (props) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();

  const [position, setPosition] = useState(0);
  const [isUp, setIsUp] = useState(false);

  let preScrollTop = 0;
  function onScroll() {
    setPosition(window.scrollY);
    let nextScrollTop = window.scrollY;
    if (preScrollTop < nextScrollTop) {
      setIsUp(false);
    } else {
      setIsUp(true);
    }
    preScrollTop = nextScrollTop;
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      {position > 150 ? (
        <div className="fadeIn">
          <img
            src="/assets/icons/circle_btn_top.png"
            style={{
              position: "fixed",
              bottom: 100,
              right: isMobile ? 20 : 100,
              width: 60,
              height: 60,
              cursor: "pointer",
              zIndex: 9999,
            }}
            alt="Back To Top Icon"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
          <img
            src="/assets/icons/circle_btn_listen.png"
            style={{
              position: "fixed",
              bottom: 30,
              right: isMobile ? 20 : 100,
              width: 60,
              height: 60,
              cursor: "pointer",
              zIndex: 9999,
            }}
            alt="Contact Icon"
            onClick={() => {
              history.push({
                pathname: route.inquiry,
              });
            }}
          />
        </div>
      ) : isUp && position < 150 ? (
        <div className="fadeOut">
          <img
            src="/assets/icons/circle_btn_top.png"
            style={{
              position: "fixed",
              bottom: 100,
              right: isMobile ? 20 : 100,
              width: 60,
              height: 60,
              cursor: "pointer",
              zIndex: 9999,
            }}
            alt="Back To Top Icon"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
          <img
            src="/assets/icons/circle_btn_listen.png"
            style={{
              position: "fixed",
              bottom: 30,
              right: isMobile ? 20 : 100,
              width: 60,
              height: 60,
              cursor: "pointer",
              zIndex: 9999,
            }}
            alt="Contact Icon"
            onClick={() => {
              history.push({
                pathname: route.inquiry,
              });
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default FooterBtnGroup;
