import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import PretendardText from "../components/atoms/pretendardText";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import MainLayout from "../components/atoms/layouts/mainLayout";
import MainContainer from "../components/atoms/layouts/mainContainer";

import HeaderM from "../components/organisms/headerM";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";

import useDisplay from "../hooks/useDisplay";

import { route } from "../routes/route";

function PaymentFail(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                height: "84vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/assets/icons/check_off.png"
                alt="Check On"
                style={{
                  width: 40,
                  height: 40,
                  marginBottom: 41,
                }}
              />
              <PretendardText
                style={{
                  fontSize: isMobile ? 18 : 60,
                  lineHeight: isMobile ? "28.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 15,
                }}
              >
                결제실패
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: isMobile ? 18 : 18,
                  lineHeight: isMobile ? "28.8px" : "28.8px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 50,
                }}
              >
                {searchParams.get("message") ? searchParams.get("message") : ""}
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  width: isMobile ? "90vw" : "100%",
                  height: 110,
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: 150,
                    height: 50,
                    flexDirection: "row",
                    borderBottom: "2px solid #353535",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: route.inquiry,
                    });
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                    }}
                  >
                    문의하기
                  </PretendardText>

                  <img
                    src="/assets//icons/ic_arrow-right.png"
                    alt="Send Icon"
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </div>
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />
      </MainColorLayout>
    </>
  );
}

export default PaymentFail;
