import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import HeaderM from "../components/organisms/headerM";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import Input from "../components/atoms/input/input";
import MainLayout from "../components/atoms/layouts/mainLayout";
import { route } from "../routes/route";
import MainContainer from "../components/atoms/layouts/mainContainer";
import { repeat } from "lodash";
import useDisplay from "../hooks/useDisplay";
import Column from "antd/lib/table/Column";
import ReviewApi from "../api/review-api";
import moment from "moment";
import VideoReviewDetailModal from "../components/templates/modals/videoReviewDetailModal";
import Pagenation from "../components/molecules/pagenation";
import VideoReviewDetailModalM from "../components/templates/modals/videoReviewDetailModalM";
import { tr } from "date-fns/locale";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";

function ReviewBrilliant(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  const { page } = useParams();
  const [pagination, setPagination] = useState({
    size: 9, // 한 페이지에 표시될 게시글 수
    rangeSize: 10, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [reviewType, setReviewType] = useState("VIDEO");
  const [isT, setIsT] = useState(false);
  const [hover, setHover] = useState(0);
  const [isInputS, setIsInputS] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setPagination({
        ...pagination,
        size: 4,
        rangeSize: 5,
      });
      setIsT(false);
    } else {
      setPagination({
        ...pagination,
        size: 9,
        rangeSize: 10,
      });

      setIsT(true);
    }
  }, [isMobile]);

  const getList = async () => {
    try {
      const data = {
        reviewType: reviewType,
        keyword: keyword,
        page: page - 1,
        size: pagination.size,
      };

      let list = (await ReviewApi.Get(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, [location, isT]);

  const [isModal, setIsModal] = useState(false);
  const [selectedData, setSelectedData] = useState({
    createdAt: "",
    writerEmail: "",
    title: "",
    content: "",
    originFileName: "",
    serverFileName: "",
    fileUrl: "",
  });

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 100,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  후기
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: isMobile ? "start" : "space-between",
                  alignItems: isMobile ? "start" : "center",
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 32 : 60,
                    lineHeight: isMobile ? "44.8px" : "84px",
                    color: "#222222",
                    fontWeight: 700,
                    marginBottom: isMobile ? 50 : 0,
                  }}
                >
                  빛나는 후기
                </PretendardText>

                <div
                  style={{
                    width: isMobile ? "90vw" : 342,
                    height: 50,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderBottom: isInputS
                      ? "1px solid #00B3CE"
                      : "1px solid #353535",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      placeholder="Search"
                      style={{
                        width: "100%",
                        border: "none",
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#222222",
                        fontWeight: 400,
                        outline: "none",
                      }}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          getList();
                        }
                      }}
                      onFocus={() => {
                        setIsInputS(true);
                      }}
                      onBlur={() => {
                        setIsInputS(false);
                      }}
                    />
                    {isInputS ? (
                      <img
                        src="/assets/icons/ic_search_f.png"
                        style={{
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          getList();
                        }}
                        alt="Search Icon"
                      />
                    ) : (
                      <img
                        src="/assets/icons/ic_search.png"
                        style={{
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          getList();
                        }}
                        alt="Search Icon"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  position: "relative",
                }}
              >
                <div
                  style={{
                    height: 1,
                    backgroundColor: "#DDDDDD",
                    position: "absolute",
                    top: isMobile ? 59 : 83,
                    left: isMobile ? "45vw" : 305,
                    width: isMobile ? "45vw" : 895,
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: isMobile ? "45vw" : 305,
                      height: isMobile ? 60 : 84,
                      border: "1px solid #DDDDDD",
                      borderBottom: "none",
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                      alignContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 20,
                        lineHeight: "32px",
                        color: "#00B3CE",
                        fontWeight: 400,
                      }}
                    >
                      영상
                    </PretendardText>
                  </div>
                  <div
                    style={{
                      width: isMobile ? "45vw" : 305,
                      height: isMobile ? 60 : 84,
                      backgroundColor: "#F7F7F7",
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                      alignContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: route.review_student + "/1",
                      });
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 20,
                        lineHeight: "32px",
                        color: "#9B9B9B",
                        fontWeight: 400,
                      }}
                    >
                      수필
                    </PretendardText>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  width: isMobile ? "90vw" : "100%",
                  gridTemplateRows: "1fr ",
                  gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr",
                  marginTop: 30,
                  marginBottom: 30,
                  gridRowGap: 30,
                  gridColumnGap: 23,
                }}
              >
                {list.map(function (review) {
                  return (
                    <div style={{ position: "relative" }} key={review.id}>
                      <div
                        style={{
                          width: isMobile ? "90vw" : 385,
                          height: isMobile ? "auto" : 279,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          position: "relative",
                          gap: "15px",
                        }}
                      >
                        <div
                          style={{
                            width: isMobile ? "" : "385px",
                            height: isMobile ? "" : "216px",
                            borderRadius: "20px",
                            position: "relative",
                            objectFit: "cover",
                          }}
                          onMouseEnter={() => setHover(review.id)} // 마우스엔터(호버)시 키값이 저장된다
                          onMouseLeave={() => setHover(0)} // 마우스리브 시에는 키값이 지워진다
                        >
                          <video
                            preload="metadata"
                            src={`${review.fileUrl}#t=0.5`}
                            muted
                            style={{
                              width: isMobile ? "100%" : 385,
                              height: isMobile ? "100%" : 216,
                              objectFit: "cover",
                              borderRadius: 20,
                            }}
                          >
                            <source src={review.fileUrl} type="video/mp4" />
                          </video>

                          <img
                            src="/assets/icons/circle_btn.png"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              display: isMobile ? "inline" : "inline",
                              width: 45,
                              height: 45,
                              cursor: "pointer",
                              zIndex: 9999,
                            }}
                            onClick={() => {
                              setSelectedData({ ...review });
                              setIsModal(true);
                            }}
                            alt="Play Icon"
                          />

                          {review.id === hover ? (
                            <div
                              style={{
                                background: "rgba(0, 179, 206, 0.2)",
                                cursor: "pointer",
                                width: isMobile ? "90vw" : "100%",
                                height: isMobile ? "50vw" : "100%",
                                zIndex: 9998,
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                borderRadius: 20,
                              }}
                            />
                          ) : null}
                        </div>

                        <div>
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "16px",
                              color: "#222222",
                              fontWeight: 700,
                            }}
                          >
                            {review.title}
                          </PretendardText>
                          <PretendardText
                            style={{
                              fontSize: 14,
                              lineHeight: "22.4px",
                              color: "#9B9B9B",
                              fontWeight: 400,
                            }}
                          >
                            {moment(review.createdAt).format("YYYY.MM.DD")}
                          </PretendardText>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagenation
                  route={route.review_brilliant}
                  currentPage={page}
                  totalPages={pagination.totalPages}
                  rangeSize={pagination.rangeSize}
                  first={pagination.first}
                  last={pagination.last}
                />
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />

        <FooterBtnGroup />

        {isModal ? (
          <VideoReviewDetailModal
            setIsModal={setIsModal}
            data={selectedData}
          ></VideoReviewDetailModal>
        ) : null}
      </MainColorLayout>
    </>
  );
}

export default ReviewBrilliant;
