import React, { useState, useEffect, useMemo } from "react";

import useDisplay from "../../hooks/useDisplay";
import PretendardText from "../atoms/pretendardText";

const PaymentItem = ({ saveData, setSaveData, service }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          width: isMobile ? "42.75vw" : 385,
          height: isMobile ? "48vw" : 174,
          borderRadius: 15,
          border: "1px solid #353535",
          background:
            saveData.inquiryType === service.key
              ? "linear-gradient(281.23deg, #00B3CE 0%, rgba(0, 179, 206, 0) 59.5%), #00B3CE"
              : "transparent",
          boxShadow: "box-shadow: 4px 5px 13px 0px #00000040",
          backgroundBlendMode:
            saveData.inquiryType === service.key ? "multiply" : "normal",
          position: "relative",
          padding: 5,
          cursor: "pointer",
        }}
        onClick={() => {
          setSaveData({
            ...saveData,
            service,
            inquiryType: service.key,
          });
        }}
      >
        <div
          style={{
            display: saveData.inquiryType === service.key ? "inline" : "none",
            position: "absolute",
            backgroundColor: "#0098BA",
            borderRadius: 50,
            top: 15,
            left: 15,
            width: 25,
            height: 25,
          }}
        />
        <img
          src="/assets/icons/ic_check_2.png"
          alt="Check Icon"
          style={{
            display: saveData.inquiryType === service.key ? "inline" : "none",
            position: "absolute",
            top: 19,
            left: 22,
            width: 19,
            height: 13,
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            borderRadius: 15,
            border:
              saveData.inquiryType === service.key
                ? "1.5px dashed #DDDDDD"
                : "none",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 14 : 24,
              lineHeight: isMobile ? "22.4px" : "38.4px",
              color:
                saveData.inquiryType === service.key ? "#FFFFFF" : "#9B9B9B",
              fontWeight: 700,

              textAlign: isMobile ? "center" : "",
            }}
            dangerouslySetInnerHTML={{
              __html: service.title,
            }}
          />

          <PretendardText
            style={{
              fontSize: isMobile ? 14 : 18,
              lineHeight: isMobile ? "22.4px" : "28.8px",
              color:
                saveData.inquiryType === service.key ? "#FFFFFF" : "#9B9B9B",
              fontWeight: 700,
              marginBottom: 5,
            }}
          >
            {service.content}
          </PretendardText>

          <PretendardText
            style={{
              fontSize: isMobile ? 18 : 20,
              lineHeight: isMobile ? "28.8px" : "32px",
              color:
                saveData.inquiryType === service.key ? "#FFFFFF" : "#9B9B9B",
              fontWeight: 700,
            }}
          >
            {service.money}
          </PretendardText>

          <PretendardText
            style={{
              marginTop: 5,
              fontSize: 12,
              lineHeight: "19.2px",
              color:
                saveData.inquiryType === service.key ? "#FFFFFF" : "#9B9B9B",
              fontWeight: 500,
              textAlign: isMobile ? "center" : "",
            }}
            dangerouslySetInnerHTML={{
              __html: isMobile ? service.mNote : service.note,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentItem;
