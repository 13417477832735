import useDisplay from "../../../hooks/useDisplay";

function MainLayout(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          padding: isMobile ? "0px 20px 0px 20px" : "0px 30px 0px 30px",
          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default MainLayout;
