import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/pretendardText";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { route } from "../../routes/route";

const HeaderText = ({ text, isMain, path, usePagination, isFixed }) => {
  const history = useHistory();
  const location = useLocation();
  const [pathName, setPathName] = useState(null);
  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const [hover, setHover] = useState(false);

  return (
    <>
      <PretendardText
        onClick={() => {
          history.push({
            pathname: usePagination ? path + "/1" : path,
          });
        }}
        onMouseEnter={() => setHover(true)} // 마우스엔터(호버)시 키값이 저장된다
        onMouseLeave={() => setHover(false)} // 마우스리브 시에는 키값이 지워진다
        style={{
          fontSize: 16,
          lineHeight: "25.6px",
          color: `${
            pathName?.includes(path) || hover
              ? "#00B3CE"
              : isMain || isFixed
              ? "#ffffff"
              : "#222222"
          }`,
          fontWeight: 700,
          cursor: "pointer",
          marginRight: 60,
        }}
      >
        {text}
      </PretendardText>
    </>
  );
};

export default HeaderText;
