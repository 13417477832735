import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import PretendardText from "../components/atoms/pretendardText";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import MainLayout from "../components/atoms/layouts/mainLayout";
import MainContainer from "../components/atoms/layouts/mainContainer";

import HeaderM from "../components/organisms/headerM";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";

import useDisplay from "../hooks/useDisplay";

import { route } from "../routes/route";
import PaymentApi from "../api/payment-api";
import { toast } from "react-toastify";

function PaymentSuccess(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function confirm() {
      let payment_data_temp = localStorage.getItem("payment_data");
      let saveData = JSON.parse(payment_data_temp);

      let serviceAmountTemp = [
        {
          serviceName: `${saveData.service.title} ${saveData.service.content}`,
          amount: Number(saveData.service.pay),
        },
      ];

      const formData = new FormData();
      formData.append("name", saveData.name);
      formData.append("payId ", searchParams.get("paymentKey"));
      formData.append("orderId ", searchParams.get("orderId"));
      formData.append("phone", saveData.phoneNumber);
      formData.append("school", saveData.school);
      formData.append("serviceAmount", JSON.stringify(serviceAmountTemp));
      formData.append("totalAmount", saveData.service.pay);

      const response = await PaymentApi.Create(formData);
      toast(response.data.message);

      return response;
    }

    confirm()
      .then((data) => {
        localStorage.removeItem("payment_data");
      })
      .catch((error) => {
        localStorage.removeItem("payment_data");

        history.push({
          pathname: route.payment_fail + `?code=${error.code}&message=${error.message}`,
        });
      });
  }, [searchParams]);

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                height: "84vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/assets/icons/check_on.png"
                alt="Check On"
                style={{
                  width: 40,
                  height: 40,
                  marginBottom: 41,
                }}
              />
              <PretendardText
                style={{
                  fontSize: isMobile ? 18 : 60,
                  lineHeight: isMobile ? "28.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 15,
                }}
              >
                결제완료
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: isMobile ? 18 : 18,
                  lineHeight: isMobile ? "28.8px" : "28.8px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 50,
                }}
              >
                1:1 코칭 서비스를 받고 입시전문가와 함께 대학 입학의 꿈을 이뤄보세요.
              </PretendardText>

              <div
                style={{
                  display: "flex",
                  width: isMobile ? "90vw" : "100%",
                  height: 110,
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: 150,
                    height: 50,
                    flexDirection: "row",
                    borderBottom: "2px solid #353535",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: route.main,
                    });
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                    }}
                  >
                    홈으로
                  </PretendardText>

                  <img
                    src="/assets//icons/ic_arrow-right.png"
                    alt="Send Icon"
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </div>
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />
      </MainColorLayout>
    </>
  );
}

export default PaymentSuccess;
