import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import PretendardText from "../../atoms/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import MainBtn from "../../atoms/button/mainBtn";
import MainBtnNotDisabled from "../../atoms/button/mainBtnNotDisabled";
import TextBtn from "../../atoms/button/textBtn";
// import GrayBtn from "../../atoms/button/grayBtn";

const ConfirmCheckModal = ({
  content,
  contentStyle,
  onBtnClick,
  onBtnText,
  onCancelBtnText,
  onCancelBtnClick,
}) => {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <ModalWrapper>
      <ModalPosition>
        <ModalContain>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {content ? (
              <PretendardText
                style={{
                  width: '295px',
                  height: '88px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',

                  textAlign: "center",
                  fontSize: 18,
                  lineHeight: "28.8px",
                  color: "#222222",
                  fontWeight: 700,
                  ...contentStyle,
                }}
              >
                {content}
              </PretendardText>
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: '10px'
              }}
            >
              <TextBtn>
                <MainBtnNotDisabled
                  onClick={() => {
                    onCancelBtnClick();
                  }}
                  style={{
                    background: "#DDDDDD",
                    width: 143.5,
                    height: 52,
                    borderRadius: 12,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "22.4px",
                      color: "#FFFFFF",
                      fontWeight: 700,
                    }}
                  >
                    {onCancelBtnText}
                  </PretendardText>
                </MainBtnNotDisabled>
              </TextBtn>

              <TextBtn>
                <MainBtnNotDisabled
                  onClick={() => {
                    onBtnClick();
                  }}
                  style={{
                    background: "#00B3CE",
                    width: 143.5,
                    height: 52,
                    borderRadius: 12,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 14,
                      lineHeight: "22.4px",
                      color: "#FFFFFF",
                      fontWeight: 700,
                    }}
                  >
                    {onBtnText}
                  </PretendardText>
                </MainBtnNotDisabled>
              </TextBtn>
            </div>
          </div>
        </ModalContain>
      </ModalPosition>
    </ModalWrapper>
  );
};

export default ConfirmCheckModal;

const ModalWrapper = styled.div.attrs((props) => { })`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background: rgba(40, 40, 40, 0.5);
  backdrop-filter: blur(2px);

  overflow: hidden;
  touch-action: none;
`;

const ModalPosition = styled.div.attrs((props) => { })`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
`;

const ModalContain = styled.div.attrs((props) => { })`
  width: "335px"
  height: "180px";
  padding: 20px 20px;
  background-color: #FFFFFF;
  border-radius: 20px;  

  overflow: auto;
`;
