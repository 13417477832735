import React, { useState, useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { route } from "../routes/route";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import MainBGLayout from "../components/atoms/layouts/mainBGLayout";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import ReviewApi from "../api/review-api";

import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import MainLayout from "../components/atoms/layouts/mainLayout";
import MainContainer from "../components/atoms/layouts/mainContainer";
import MainBGLayout02 from "../components/atoms/layouts/mainBGLayout02";
import MainBGLayout03 from "../components/atoms/layouts/mainBGLayout03";
import useDisplay from "../hooks/useDisplay";
import HeaderM from "../components/organisms/headerM";
import VideoReviewDetailModal from "../components/templates/modals/videoReviewDetailModal";
import VideoReviewDetailModalM from "../components/templates/modals/videoReviewDetailModalM";
import MainLinkBtn from "../components/atoms/button/mainLinkBtn";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";
import PopupApi from "../api/popup-api";
import PopupModal from "../components/templates/modals/popupModal";

function Main(props) {
  const history = useHistory();
  const sliderRef = useRef();
  const { isMobile, isTablet, isDesktop } = useDisplay();

  const [width, setWidth] = useState(window.innerWidth);

  const [activeIndex, setActiveIndex] = useState(0);

  const location = useLocation();

  const { page } = useParams(0);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 10, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [reviewType, setReviewType] = useState("VIDEO");
  const getList = async () => {
    try {
      const data = {
        reviewType: reviewType,
        keyword: keyword,
        page: page,
        size: pagination.size,
      };

      let list = (await ReviewApi.Get(data)).data.data.content;
      let listTemp = JSON.parse(JSON.stringify(list));
      const length = list.length;

      if (length < 6) {
        for (let index = 0; index < length; index++) {
          let element = listTemp[index];
          list.push(element);
        }

        if (length === 1) {
          for (let index = 0; index < 4; index++) {
            let element = listTemp[0];
            list.push(element);
          }
        } else if (length === 2) {
          for (let index = 0; index < 2; index++) {
            let element = listTemp[index];
            list.push(element);
          }
        }
      }

      setList(
        list.map((item) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getList();
  }, [location]);

  const [isModal, setIsModal] = useState(false);
  const [selectedData, setSelectedData] = useState({
    createdAt: "",
    writerEmail: "",
    title: "",
    content: "",
    originFileName: "",
    serverFileName: "",
    fileUrl: "",
  });

  const [popupList, setPopupList] = useState([]);
  const [popupDelList, setPopupDelList] = useState([]);
  const getPopupList = async () => {
    try {
      const popupDate = localStorage.getItem("popup_date");
      let date = toStringByFormatting(new Date());
      let popupDelList;

      if (popupDate !== date) {
        localStorage.removeItem("popup_list");
      } else {
        popupDelList = JSON.parse(localStorage.getItem("popup_list"));
      }

      if (popupDelList) {
        let temp = popupDelList;
        const response = await PopupApi.GetActivePopup();

        let data;
        if (response.data.data.length > 0) {
          data = response.data.data.map((item) => {
            if (temp.some((item02) => item02 == item.id)) {
              return null;
            } else {
              return item;
            }
          });
        } else {
          data = response.data.data;
        }

        setPopupList(data);
      } else {
        const response = await PopupApi.GetActivePopup();
        setPopupList([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPopupList();
  }, []);

  function toStringByFormatting(source, delimiter = "-") {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());

    return year + "/" + month + "/" + day;
  }

  function leftPad(value) {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  }

  const popupCloseFunc = (id) => {
    try {
      console.log(id);
      let date = toStringByFormatting(new Date());
      let popupId = localStorage.getItem("popup_list");

      if (popupId) {
        let popupIdTemp = JSON.parse(popupId);
        popupIdTemp.push(id);
        localStorage.setItem("popup_list", JSON.stringify(popupIdTemp));
      } else {
        let popupIdTemp = [id];
        localStorage.setItem("popup_list", JSON.stringify(popupIdTemp));
      }

      localStorage.setItem("popup_date", date);
    } catch (err) {}
  };

  return (
    <>
      <MainColorLayout>
        <MainBGLayout>
          {isMobile ? <HeaderM main={true} /> : <Header main={true} />}

          <div
            style={{
              flex: 1,
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              padding: isMobile ? "0px 20px 80px 20px" : "0px",
            }}
          >
            <PretendardText
              style={{
                fontSize: isMobile ? 20 : 40,
                lineHeight: isMobile ? "28px" : "56px",
                color: isMobile ? "#E6E6E6" : "#FFFFFF",
                fontWeight: 400,
                letterSpacing: isMobile ? "-0.32px" : "-0.6px",
              }}
            >
              합격을 넘어 꿈을 이룰 나만의 브랜드화
            </PretendardText>
            <img
              src="/assets/icons/main_logo.svg"
              alt="Edu at Talk Logo Image"
              style={{
                width: isMobile ? "100%" : 631,
                height: isMobile ? 73 : 140,
              }}
            />

            <div
              style={{
                position: "absolute",
                bottom: isMobile ? -800 : -250,
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? 0 : 22,
              }}
            >
              <img
                className="fadeInUp"
                src={isMobile ? "/assets/images/main_top01_m.png" : "/assets/images/main_top01.png"}
                style={{
                  width: isMobile ? 335 : 385,
                  height: isMobile ? 300 : 500,
                  marginBottom: isMobile ? 20 : 0,
                  borderRadius: "20px",
                  background: "linear-gradient(180deg, #FFF 20.5%, rgba(255, 255, 255, 0.00) 46.2%), url(<path-to-image>), lightgray -359.742px 22.146px / 208.547% 107.028% no-repeat",
                  boxShadow: "2px 12px 18px 0px rgba(0, 0, 0, 0.29)",
                }}
                alt="SAC Patent"
              />
              <img
                className="fadeInUp"
                src={isMobile ? "/assets/images/main_top02_m.png" : "/assets/images/main_top02.png"}
                style={{
                  width: isMobile ? 335 : 385,
                  height: isMobile ? 300 : 500,
                  marginBottom: isMobile ? 20 : 0,
                  borderRadius: "20px",
                  background: "linear-gradient(180deg, #FFF 20.5%, rgba(255, 255, 255, 0.00) 46.2%), url(<path-to-image>), lightgray -359.742px 22.146px / 208.547% 107.028% no-repeat",
                  boxShadow: "2px 12px 18px 0px rgba(0, 0, 0, 0.29)",
                }}
                alt="1on1 Coaching"
              />
              <img
                className="fadeInUp"
                src={isMobile ? "/assets/images/main_top03_m.png" : "/assets/images/main_top03.png"}
                style={{
                  width: isMobile ? 335 : 385,
                  height: isMobile ? 300 : 500,
                  borderRadius: "20px",
                  background: "linear-gradient(180deg, #FFF 20.5%, rgba(255, 255, 255, 0.00) 46.2%), url(<path-to-image>), lightgray -359.742px 22.146px / 208.547% 107.028% no-repeat",
                  boxShadow: "2px 12px 18px 0px rgba(0, 0, 0, 0.29)",
                }}
                alt="Time Work"
              />
            </div>
          </div>
        </MainBGLayout>

        <MainLayout>
          <MainContainer
            style={{
              height: isMobile ? 510 : 300,
              marginTop: isMobile ? 879 : 369,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: isMobile ? "flex-start" : "space-between",
                alignItems: isMobile ? "flex-start" : "flex-start",
                position: "relative",
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 24 : 32,
                  lineHeight: isMobile ? "38.4px" : "44.8px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 30 : 0,
                }}
              >
                입시전문가들의 다년간
                <br />
                쌓은 노하우로 관리합니다.
              </PretendardText>
              {isMobile ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "-webkit-inline-box",
                      whiteSpace: "pre-wrap",
                      width: "100%",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 18,
                        lineHeight: "28.8px",
                        color: "#555555",
                        fontWeight: 400,
                      }}
                    >
                      에듀엣톡은 학생들에게 맞춤형 코칭 서비스를 통해
                      <br />
                      대학 입시 컨설팅과 생기부 관리를 포함한
                      <br />
                      고교 학점제 대비 탐구학습을 위한 다양한 정보와
                      <br />
                      도구를 제공합니다.
                      <br />
                      또한, 내신대비방법을 지도하여 학생들이 해답을
                      <br />
                      주도적으로 찾아가며 성장하도록 돕고 있습니다.
                    </PretendardText>
                  </div>

                  <MainLinkBtn
                    style={{
                      alignSelf: "self-start",
                      marginTop: 20,
                    }}
                    text={"문의 바로가기"}
                    path={route.inquiry}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "-webkit-inline-box",
                      whiteSpace: "pre-wrap",
                      width: 643,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 18,
                        lineHeight: "28.8px",
                        color: "#555555",
                        fontWeight: 400,
                      }}
                    >
                      에듀엣톡은 학생들에게 맞춤형 코칭 서비스를 통해 대학 입시 컨설팅과 생기부 관리를 포함한
                      <br />
                      고교 학점제 대비 탐구학습을 위한 다양한 정보와 도구를 제공합니다.
                      <br />
                      또한, 내신대비방법을 지도하여 학생들이 해답을 주도적으로 찾아가며 성장하도록 돕고 있습니다.
                    </PretendardText>
                  </div>

                  <MainLinkBtn
                    style={{
                      alignSelf: "self-start",
                      marginTop: 20,
                    }}
                    text={"문의 바로가기"}
                    path={route.inquiry}
                  />
                </div>
              )}
            </div>
          </MainContainer>
        </MainLayout>

        <MainBGLayout02>
          <MainContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: isMobile ? 34 : 0,
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 24 : 32,
                  lineHeight: isMobile ? "38.4px" : "44.8px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                입시전문가와 함께 수시에서 <br />
                합격하는 절대 법칙
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 18,
                  lineHeight: "28.8px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 30,
                }}
              >
                학생부중심전형으로 합격하는 방법, 의외로 단순합니다.
              </PretendardText>

              <MainLinkBtn
                style={{
                  alignSelf: "self-start",
                }}
                text={"소개 바로가기"}
                path={route.eduatalk_intro}
              />
            </div>
          </MainContainer>
        </MainBGLayout02>

        {list.length > 0 ? (
          <div
            style={{
              background: "#1E1E1E",
              display: "flex",
              flexDirection: "column",
              padding: isMobile ? "100px 0px" : "103px 0px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 24 : 32,
                  lineHeight: isMobile ? "38.4px" : "44.8px",
                  color: "#FFFFFF",
                  fontWeight: 700,
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                꿈을 이룬 선배들의{isMobile ? <br /> : null} 이야기를 살펴보세요
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 18,
                  lineHeight: "28.8px",
                  color: "#FFFFFF",
                  fontWeight: 400,
                  textAlign: "center",
                  marginBottom: isMobile ? 30 : 40,
                }}
              >
                해답을 찾아가는 탐구학습을 통해{isMobile ? <br /> : null} 주도적인 학생의 성장과 발전을 돕습니다.
              </PretendardText>
            </div>

            <div
              style={{
                width: isMobile ? "100vw" : width > 1360 ? "99vw" : 1360,
                position: "relative",
              }}
            >
              <div
                onClick={() => sliderRef.current?.slidePrev()}
                className="main-slider-chevron"
                style={{
                  position: "absolute",
                  left: isMobile ? 20 : "29.2%",
                  cursor: "pointer",
                  zIndex: 99,
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img
                  src="/assets/icons/play_right.png"
                  style={{
                    width: 45,
                    height: 45,
                  }}
                  alt=""
                />
              </div>
              <div
                onClick={() => {
                  sliderRef.current?.slideNext();
                }}
                className="main-slider-chevron"
                style={{
                  position: "absolute",
                  right: isMobile ? -10 : "26.8%",
                  cursor: "pointer",
                  zIndex: 99,
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img
                  src="/assets/icons/play_left.png"
                  style={{
                    width: 45,
                    height: 45,
                  }}
                  alt=""
                />
              </div>
              <div
                onClick={() => {
                  setSelectedData({ ...list[activeIndex] });
                  setIsModal(true);
                }}
                className="main-slider-chevron"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  zIndex: 99,
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img
                  src="/assets/icons/play.png"
                  style={{
                    width: isMobile ? 30 : 60,
                    height: isMobile ? 30 : 60,
                  }}
                />
              </div>

              <Swiper
                slidesPerView={isMobile ? 1.12 : 3}
                spaceBetween={isMobile ? 10 : 20}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                onSwiper={(it) => {
                  sliderRef.current = it;
                }}
                navigation={false}
                modules={[Autoplay]}
                initialSlide={activeIndex}
                onSlideChange={(swiper) => {
                  setActiveIndex(swiper.realIndex);
                }}
                loop={true}
                loopedSlides={isMobile ? 1.12 : 3}
                dataLength={list.length}
                inverse={true}
                style={{ display: "flex", flexDirection: "column-reverse" }}
              >
                {list.length > 0
                  ? list.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={index}
                          style={{
                            display: "flex",
                            position: "relative",
                            justifyContent: "center",
                            alignItems: "center",
                            height: isMobile ? 187 : 410,
                            borderRadius: "20px",
                          }}
                        >
                          <video
                            muted
                            style={{
                              width: "100%",
                              height: isMobile ? "187px" : index === activeIndex ? "410px" : "376px",
                              objectFit: "cover",
                              borderRadius: 20,
                            }}
                          >
                            <source src={item.fileUrl} type="video/mp4" />
                          </video>

                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: isMobile ? "187px" : index === activeIndex ? "410px" : "376px",
                              background: index === activeIndex ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.7)",
                              borderRadius: "20px",
                            }}
                          />
                        </SwiperSlide>
                      );
                    })
                  : null}
              </Swiper>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                marginTop: 40,
              }}
            >
              <MainLinkBtn text={"리뷰 바로가기"} path={`${route.review_brilliant}/1`} isWhite={true} />
            </div>
          </div>
        ) : (
          <div
            style={{
              background: "#1E1E1E",
              display: "flex",
              flexDirection: "column",
              padding: isMobile ? "100px 0px" : "103px 0px",
              width: "100%",
              height: isMobile ? "651px" : "830px",
              backgroundImage: isMobile ? 'url("/assets/images/slide_empty_bg_m.png")' : 'url("/assets/images/slide_empty_bg.png")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <PretendardText
                style={{
                  fontSize: isMobile ? 24 : 32,
                  lineHeight: isMobile ? "38.4px" : "44.8px",
                  color: "#FFFFFF",
                  fontWeight: 700,
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                꿈을 이룬 선배들의{isMobile ? <br /> : null} 이야기를 살펴보세요
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 18,
                  lineHeight: "28.8px",
                  color: "#FFFFFF",
                  fontWeight: 400,
                  textAlign: "center",
                  marginBottom: isMobile ? 63 : 209,
                }}
              >
                해답을 찾아가는 탐구학습을 통해{isMobile ? <br /> : null} 주도적인 학생의 성장과 발전을 돕습니다.
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  textAlign: "center",
                  // marginTop: isMobile ? 63 : 209,
                }}
              >
                준비된 영상이 없습니다.
              </PretendardText>
            </div>
          </div>
        )}

        <MainLayout
          style={{
            background: "#F9F9F9",
            padding: isMobile ? "100px 20px" : "100px 0px",
          }}
        >
          <MainContainer
            style={{
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: isMobile ? 0 : 20,
                  marginBottom: isMobile ? 30 : 0,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 24 : 32,
                    lineHeight: isMobile ? "38.4px" : "44.8px",
                    color: "#222222",
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  에듀엣톡에서 진로설계의 시작, {isMobile ? <br /> : null}
                  함께하세요
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 18,
                    lineHeight: "28.8px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  입시는 타이밍! 맞춤형 생활기록부 관리를 위한 <br />
                  1:1 코칭 서비스를 받고 입시전문가와 함께 {isMobile ? <br /> : null}대학 입학의 꿈을 이뤄보세요.
                </PretendardText>
              </div>

              <div
                style={{
                  padding: "30px 20px",
                  borderRadius: 20,
                  width: isMobile ? "100%" : 385,
                  height: isMobile ? 230 : 250,
                  background: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src="/assets/icons/career01.png"
                  alt="Five-Pointed Star Icon"
                  style={{
                    width: 50,
                    height: 50,
                    marginBottom: 10,
                  }}
                />
                <PretendardText
                  style={{
                    fontSize: 24,
                    lineHeight: "38.4px",
                    color: "#222222",
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  진로적성검사
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#353535",
                    fontWeight: 400,
                    marginBottom: isMobile ? 14 : 30,
                  }}
                >
                  내 적성, 희망진로를 알고싶다면
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src="/assets/icons/ic_arrow-right.png"
                    alt="Arrow Right Icon"
                    style={{
                      width: 24,
                      height: 24,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: route.career_test,
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              {/* <div
                style={{
                  padding: "30px 20px",
                  borderRadius: 20,
                  width: isMobile ? "100%" : 385,
                  height: isMobile ? 230 : 250,
                  background: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: isMobile ? 20 : 0,
                }}
              >
                <img
                  src="/assets/icons/career02.png"
                  alt="School Icon"
                  style={{
                    width: 50,
                    height: 50,
                    marginBottom: 10,
                  }}
                />
                <PretendardText
                  style={{
                    fontSize: 24,
                    lineHeight: "38.4px",
                    color: "#222222",
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  고입준비
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#353535",
                    fontWeight: 400,
                    marginBottom: isMobile ? 14 : 30,
                  }}
                >
                  고입을 앞두고 있다면
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src="/assets/icons/ic_arrow-right.png"
                    alt="Arrow Right Icon"
                    style={{
                      width: 24,
                      height: 24,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: route.high_entrance,
                      });
                    }}
                  />
                </div>
              </div> */}

              <div
                style={{
                  padding: "30px 20px",
                  borderRadius: 20,
                  width: isMobile ? "100%" : 385,
                  height: isMobile ? 230 : 250,
                  background: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: isMobile ? 0 : 20,
                }}
              >
                <img
                  src="/assets/icons/career03.png"
                  alt="College Icon"
                  style={{
                    width: 50,
                    height: 50,
                    marginBottom: 10,
                  }}
                />
                <PretendardText
                  style={{
                    fontSize: 24,
                    lineHeight: "38.4px",
                    color: "#222222",
                    fontWeight: 700,
                    marginBottom: 10,
                  }}
                >
                  대입 준비
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#353535",
                    fontWeight: 400,
                    marginBottom: isMobile ? 14 : 30,
                  }}
                >
                  대입을 앞두고 있다면
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src="/assets/icons/ic_arrow-right.png"
                    alt="Arrow Right Icon"
                    style={{
                      width: 24,
                      height: 24,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: route.college_entrance,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <MainBGLayout03>
          <MainContainer>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src="/assets/icons/logo_mb.svg"
                  alt="Edu at Talk Logo Image"
                  style={{
                    width: 93,
                    height: 26,
                  }}
                />
                <PretendardText
                  style={{
                    fontSize: isMobile ? 24 : 32,
                    lineHeight: isMobile ? "38.4px" : "44.8px",
                    color: "#FFFFFF",
                    fontWeight: 700,
                    marginTop: 10,
                  }}
                >
                  모바일을 통해 1:1 코칭 서비스를 간편하게 받아보세요
                </PretendardText>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: isMobile ? 30 : 0,
                  width: isMobile ? "100%" : "auto",
                }}
              >
                <img
                  src={isMobile ? "/assets/icons/google_store_m.png" : "/assets/icons/google_store.svg"}
                  alt="Google Play Icon"
                  style={{
                    width: isMobile ? "100%" : 140,
                    height: isMobile ? "54px" : 50,
                    marginRight: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://play.google.com/store/apps/details?id=com.eduatalk.eduatalk&hl=ko-KR");
                  }}
                />
                <img
                  src={isMobile ? "/assets/icons/apple_store_m.png" : "/assets/icons/apple_store.svg"}
                  alt="Apple Store Icon"
                  style={{
                    width: isMobile ? "100%" : 140,
                    height: isMobile ? "54px" : 50,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://apps.apple.com/kr/app/%EC%97%90%EB%93%80%EC%97%A3%ED%86%A1-%EC%A3%BC/id6472698636");
                  }}
                />
              </div>
            </div>
          </MainContainer>
        </MainBGLayout03>

        <Footer />

        <FooterBtnGroup />
        {isModal && selectedData.fileUrl ? <VideoReviewDetailModal setIsModal={setIsModal} data={selectedData}></VideoReviewDetailModal> : null}

        {popupList.length > 0
          ? popupList.map((item, index) => {
              if (item == null) {
                return;
              }

              if (popupDelList.some((item02) => item02 === item.id)) {
                return;
              }

              return (
                <PopupModal
                  key={index}
                  data={item}
                  btnOnPress={() => {
                    popupCloseFunc(item.id);
                    setPopupDelList([...popupDelList, item.id]);
                  }}
                  btnConfirmPress={() => {
                    setPopupDelList([...popupDelList, item.id]);
                  }}
                />
              );
            })
          : null}
      </MainColorLayout>
    </>
  );
}

export default Main;
