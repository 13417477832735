import { Route, Switch, Redirect } from "react-router-dom";
import { route } from "./route";

import Main from "../pages/main";
import Term from "../pages/term";
import Policy from "../pages/policy";
import EduatalkIntro from "../pages/eduatalkIntro";
import ProgramIntro from "../pages/programIntro";
import CareerTest from "../pages/careerTest";
import HighEntrance from "../pages/highEntrance";
import CollegeEntrance from "../pages/collegeEntrance";
import ReviewBrilliant from "../pages/reviewBrilliant";
import ReviewStudent from "../pages/reviewStudent";
import ReviewStudentDetail from "../pages/reviewStudentDetail";
import Inquiry from "../pages/inquiry";
import ServicePayment from "../pages/servicePayment";
import Refund from "../pages/refund";
import PaymentFail from "../pages/paymentFail";
import PaymentSuccess from "../pages/paymentSuccess";

const Router = () => {
  return (
    <Switch>
      <Route exact={true} path={"/"} component={Main} />
      <Route exact={true} path={route.main} component={Main} />

      <Route path={route.term} component={Term} />
      <Route path={route.policy} component={Policy} />
      <Route path={route.refund} component={Refund} />
      <Route path={route.eduatalk_intro} component={EduatalkIntro} />
      <Route path={route.program_intro} component={ProgramIntro} />
      <Route path={route.career_test} component={CareerTest} />
      <Route path={route.high_entrance} component={HighEntrance} />
      <Route path={route.college_entrance} component={CollegeEntrance} />
      <Route
        path={route.review_brilliant + "/:page"}
        component={ReviewBrilliant}
      />
      <Route path={route.review_student + "/:page"} component={ReviewStudent} />
      <Route
        path={route.review_student_detail + "/:id"}
        component={ReviewStudentDetail}
      />
      <Route path={route.inquiry} component={Inquiry} />
      <Route path={route.service_payment} component={ServicePayment} />
      <Route path={route.payment_fail} component={PaymentFail} />
      <Route path={route.payment_success} component={PaymentSuccess} />

      {/* Not Found */}
      <Route component={() => <Redirect to={route.main} />} />
    </Switch>
  );
};

export default Router;
