import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../components/organisms/header";
import HeaderM from "../components/organisms/headerM";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import Input from "../components/atoms/input/input";
import MainLayout from "../components/atoms/layouts/mainLayout";
import { route } from "../routes/route";
import MainContainer from "../components/atoms/layouts/mainContainer";
import InfoBGLayout from "../components/atoms/layouts/infoBGLayout";
import { max } from "lodash";
import useDisplay from "../hooks/useDisplay";
import styled from "styled-components";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";

function EduatalkIntro(props) {
  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [position, setPosition] = useState(0);

  function onScroll() {
    setPosition(window.scrollY);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 100,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  에듀엣톡 소개
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: isMobile ? 32 : 60,
                  lineHeight: isMobile ? "44.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                에듀엣톡 소개
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                학생부 종합 전형 합격 방법, {isMobile ? <br /> : null}의외로
                단순합니다.
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 30,
                }}
              >
                학생부 종합 전형은 막연하거나 입학사정관마다{" "}
                {isMobile ? <br /> : null}평가기준이 다르지 않습니다! <br />
                1차 합격자 선발 평가 기준이 있어 목표와 방향만{" "}
                {isMobile ? <br /> : null}뚜렷하다면 합격할 수 있습니다
              </PretendardText>

              <img
                src={
                  isMobile
                    ? "/assets/images/info_bg_m.png"
                    : "/assets/images/intro_bg01.png"
                }
                alt="Intro Banner"
                style={{
                  width: isMobile ? "90vw" : "100%",
                  marginBottom: 100,
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  width: isMobile ? "90vw" : "100%",
                  height: isMobile ? "auto" : 380,
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "start",
                  marginBottom: 100,
                  paddingBottom: isMobile ? 100 : 0,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 24,
                      lineHeight: "38.4px",
                      color: "#222222",
                      fontWeight: 700,
                      marginBottom: 10,
                    }}
                  >
                    다년간 쌓은 노하우
                  </PretendardText>

                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#555555",
                      fontWeight: 400,
                      marginBottom: isMobile ? 30 : 0,
                    }}
                  >
                    입시 정보 격차를 해결하고, 꿈과 희망을 향해서{" "}
                    {isMobile ? <br /> : null}나아가는 고등학생들을
                    {isMobile ? null : <br />}
                    실질적으로 돕기 위해 {isMobile ? <br /> : null}에듀엣톡
                    입시전문가들의 다년간 쌓은 노하우로{" "}
                    {isMobile ? <br /> : null}관리합니다.
                  </PretendardText>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: isMobile ? "90vw" : "100%",
                      alignItems: "start",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      style={{
                        width: isMobile ? "100%" : 285,
                        borderTop: "1px solid #DDDDDD",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 14,
                          lineHeight: "22.4px",
                          color: "#222222",
                          fontWeight: 400,
                          marginTop: 10,
                          marginBottom: 30,
                        }}
                      >
                        01
                      </PretendardText>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginBottom: isMobile ? 15 : 40,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                          }}
                        >
                          10경력이상의
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          입시 최고 전문가 선생님
                        </PretendardText>
                      </div>
                    </div>

                    <div
                      style={{
                        width: isMobile ? "100%" : 285,
                        borderTop: "1px solid #DDDDDD",
                        marginLeft: 40,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 14,
                          lineHeight: "22.4px",
                          color: "#222222",
                          fontWeight: 400,
                          marginTop: 10,
                          marginBottom: 30,
                        }}
                      >
                        02
                      </PretendardText>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginBottom: isMobile ? 15 : 40,
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                          }}
                        >
                          6만건 이상의
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          합격 성공사례 데이터
                        </PretendardText>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: isMobile ? "90vw" : "100%",
                      alignItems: "start",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      style={{
                        width: isMobile ? "100%" : 285,

                        borderTop: "1px solid #DDDDDD",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 14,
                          lineHeight: "22.4px",
                          color: "#222222",
                          fontWeight: 400,
                          marginTop: 10,
                          marginBottom: 30,
                        }}
                      >
                        03
                      </PretendardText>
                      <div
                        style={{
                          display: "flex",

                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                          }}
                        >
                          효율적인 학습보조
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          타임워크 시스템
                        </PretendardText>
                      </div>
                    </div>

                    <div
                      style={{
                        width: isMobile ? "100%" : 285,

                        borderTop: "1px solid #DDDDDD",
                        marginLeft: 40,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 14,
                          lineHeight: "22.4px",
                          color: "#222222",
                          fontWeight: 400,
                          marginTop: 10,
                          marginBottom: 30,
                        }}
                      >
                        04
                      </PretendardText>
                      <div
                        style={{
                          display: "flex",

                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                          }}
                        >
                          1:1 생기부 관리
                        </PretendardText>
                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          개열 코칭
                        </PretendardText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 30,
                }}
              >
                에듀엣톡만의 합격을 위한{isMobile ? <br /> : null} 특별한 서비스
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  width: isMobile ? "90vw" : "100%",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: isMobile ? "start" : "center",
                  minHeight: 400,
                }}
              >
                {isMobile ? (
                  position > 1100 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_top01_m.png"
                          : "/assets/images/info_top01.png"
                      }
                      alt="SAC Patent"
                      style={{
                        width: isMobile ? "90vw" : 385,
                        height: isMobile ? "auto" : 500,
                        marginRight: isMobile ? 0 : 22,
                      }}
                    />
                  ) : null
                ) : position > 700 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_top01_m.png"
                        : "/assets/images/info_top01.png"
                    }
                    alt="SAC Patent"
                    style={{
                      width: isMobile ? "90vw" : 385,
                      height: isMobile ? "auto" : 500,
                      marginRight: isMobile ? 0 : 22,
                    }}
                  />
                ) : null}

                {isMobile ? (
                  position > 1340 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_top02_m.png"
                          : "/assets/images/info_top02.png"
                      }
                      alt="1on1 Coaching"
                      style={{
                        width: isMobile ? "90vw" : 385,
                        height: isMobile ? "auto" : 500,
                        marginTop: isMobile ? 20 : 0,
                        marginRight: isMobile ? 0 : 22,
                      }}
                    />
                  ) : null
                ) : position > 700 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_top02_m.png"
                        : "/assets/images/info_top02.png"
                    }
                    alt="1on1 Coaching"
                    style={{
                      width: isMobile ? "90vw" : 385,
                      height: isMobile ? "auto" : 500,
                      marginTop: isMobile ? 20 : 0,
                      marginRight: isMobile ? 0 : 22,
                    }}
                  />
                ) : null}

                {isMobile ? (
                  position > 1660 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_top03_m.png"
                          : "/assets/images/info_top03.png"
                      }
                      alt="Time Work"
                      style={{
                        width: isMobile ? "90vw" : 385,
                        height: isMobile ? "auto" : 500,
                        marginTop: isMobile ? 20 : 0,
                      }}
                    />
                  ) : null
                ) : position > 700 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_top03_m.png"
                        : "/assets/images/info_top03.png"
                    }
                    alt="Time Work"
                    style={{
                      width: isMobile ? "90vw" : 385,
                      height: isMobile ? "auto" : 500,
                      marginTop: isMobile ? 20 : 0,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </MainContainer>
        </MainLayout>
        <InfoBGLayout>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: isMobile ? 317 : 462,
              height: isMobile ? 216 : 107,
              justifyContent: "space-between",
            }}
          >
            <PretendardText
              style={{
                fontSize: 32,
                lineHeight: "44.8px",
                color: "#FFFFFF",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              정답이 아닌 해답을{isMobile ? <br /> : null} 찾아가는 탐구학습
            </PretendardText>

            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "25.6px",
                color: "#FFFFFF",
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              에듀엣톡은 대필하지 않습니다.{isMobile ? <br /> : null} 실제로
              탐구하게 합니다 !<br />
              학생이 직접 물음에 쫒아 해답을 찾을 수 있도록
              {isMobile ? <br /> : null} 구체적인 가이드를 제공합니다.
            </PretendardText>
          </div>
        </InfoBGLayout>

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 100,
                paddingTop: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 30,
                }}
              >
                에듀엣톡의 대표 컨설턴트를{isMobile ? <br /> : null} 소개합니다.
              </PretendardText>

              {/* 선생님 프로필 */}
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 50 : 30,
                  justifyContent: "space-between",
                }}
              >
                {isMobile ? (
                  position > 2770 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_port01_m.png"
                          : "/assets/images/info_port01.png"
                      }
                      alt="에듀엣톡 대표 장미희"
                      style={{
                        width: isMobile ? "90vw" : 585,
                        height: isMobile ? "auto" : 342,
                        marginRight: isMobile ? 0 : 30,
                      }}
                    />
                  ) : null
                ) : position > 1710 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_port01_m.png"
                        : "/assets/images/info_port01.png"
                    }
                    alt="에듀엣톡 대표 장미희"
                    style={{
                      width: isMobile ? "90vw" : 585,
                      height: isMobile ? "auto" : 342,
                      marginRight: isMobile ? 0 : 30,
                    }}
                  />
                ) : null}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    marginTop: 30,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: isMobile ? "90vw" : "100%",
                      height: isMobile ? "auto" : 104,
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      borderBottom: "1px solid #DDDDDD",
                      paddingBottom: isMobile ? 20 : 0,
                      marginBottom: 20,
                    }}
                  >
                    {isMobile ? (
                      position > 3100 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                            marginBottom: 5,
                          }}
                        >
                          에듀엣톡 대표 장미희
                        </TextRightAnimation>
                      ) : null
                    ) : position > 1710 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 20,
                          lineHeight: "32px",
                          color: "#222222",
                          fontWeight: 700,
                          marginBottom: 5,
                        }}
                      >
                        에듀엣톡 대표 장미희
                      </TextRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 3200 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          지난 10여년 기간동안 학생들과 함께 뛴 경험과{" "}
                          {isMobile ? <br /> : null}노하우로, 합격을 넘어{" "}
                          {isMobile ? null : <br />}
                          꿈을 이룰 비전으로 {isMobile ? <br /> : null}
                          지도하겠습니다.
                        </TextRightAnimation>
                      ) : null
                    ) : position > 1720 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#555555",
                          fontWeight: 400,
                        }}
                      >
                        지난 10여년 기간동안 학생들과 함께 뛴 경험과{" "}
                        {isMobile ? <br /> : null}노하우로, 합격을 넘어{" "}
                        {isMobile ? null : <br />}
                        꿈을 이룰 비전으로 {isMobile ? <br /> : null}
                        지도하겠습니다.
                      </TextRightAnimation>
                    ) : null}
                  </div>
                  <ul
                    style={{
                      paddingLeft: isMobile ? 25 : 20,
                      marginBottom: 0,
                    }}
                  >
                    {isMobile ? (
                      position > 3268 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              marginBottom: 5,
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              인문/자연 전 계열 입시컨설팅
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              경력 12년
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 1730 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            marginBottom: 5,
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            인문/자연 전 계열 입시컨설팅
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            경력 12년
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 3274 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              전 메가스터디 러셀 교육평가
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              전임연구원
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 1730 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            전 메가스터디 러셀 교육평가
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            전임연구원
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}
                  </ul>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 50 : 30,
                  justifyContent: "space-between",
                }}
              >
                {isMobile ? (
                  position > 3430 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_port02_m.png"
                          : "/assets/images/info_port02.png"
                      }
                      alt="에듀엣톡 컨설턴트 김영환"
                      style={{
                        width: isMobile ? "90vw" : 585,
                        height: isMobile ? "auto" : 342,
                        marginRight: isMobile ? 0 : 30,
                      }}
                    />
                  ) : null
                ) : position > 2100 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_port02_m.png"
                        : "/assets/images/info_port02.png"
                    }
                    alt="에듀엣톡 컨설턴트 김영환"
                    style={{
                      width: isMobile ? "90vw" : 585,
                      height: isMobile ? "auto" : 342,
                      marginRight: isMobile ? 0 : 30,
                    }}
                  />
                ) : null}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    marginTop: 30,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: isMobile ? "90vw" : "100%",
                      height: isMobile ? "auto" : 104,
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      borderBottom: "1px solid #DDDDDD",
                      paddingBottom: isMobile ? 20 : 0,
                      marginBottom: 20,
                    }}
                  >
                    {isMobile ? (
                      position > 3752 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                            marginBottom: 5,
                          }}
                        >
                          에듀엣톡 컨설턴트 김영환
                        </TextRightAnimation>
                      ) : null
                    ) : position > 2100 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 20,
                          lineHeight: "32px",
                          color: "#222222",
                          fontWeight: 700,
                          marginBottom: 5,
                        }}
                      >
                        에듀엣톡 컨설턴트 김영환
                      </TextRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 3787 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          흔히 입시는 어렵다고들 합니다.
                          {isMobile ? <br /> : null}
                          하지만 에듀엣톡에서는 무엇을{" "}
                          {isMobile ? null : <br />}
                          어떻게 준비해야 할지 {isMobile ? <br /> : null}
                          몰라 입시에 실패하는 일이 없도록 하겠습니다
                          {isMobile ? <br /> : null}
                        </TextRightAnimation>
                      ) : null
                    ) : position > 2100 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#555555",
                          fontWeight: 400,
                        }}
                      >
                        흔히 입시는 어렵다고들 합니다.{isMobile ? <br /> : null}
                        하지만 에듀엣톡에서는 무엇을 {isMobile ? null : <br />}
                        어떻게 준비해야 할지 {isMobile ? <br /> : null}
                        몰라 입시에 실패하는 일이 없도록 하겠습니다
                        {isMobile ? <br /> : null}
                      </TextRightAnimation>
                    ) : null}
                  </div>
                  <ul
                    style={{
                      paddingLeft: isMobile ? 25 : 20,
                      marginBottom: 0,
                    }}
                  >
                    {isMobile ? (
                      position > 3906 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              marginBottom: 5,
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              인문/자연 전 계열 입시컨설팅
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              경력 13년
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 2150 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            marginBottom: 5,
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            인문/자연 전 계열 입시컨설팅
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            경력 13년
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 3932 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              전 메가스터디 러셀 교육평가
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              수석연구원
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 2160 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            전 메가스터디 러셀 교육평가
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            수석연구원
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}
                  </ul>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 50 : 30,
                  justifyContent: "space-between",
                }}
              >
                {isMobile ? (
                  position > 4041 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_port03_m.png"
                          : "/assets/images/info_port03.png"
                      }
                      alt="에듀엣톡 컨설턴트 송민재"
                      style={{
                        width: isMobile ? "90vw" : 585,
                        height: isMobile ? "auto" : 342,
                        marginRight: isMobile ? 0 : 30,
                      }}
                    />
                  ) : null
                ) : position > 2460 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_port03_m.png"
                        : "/assets/images/info_port03.png"
                    }
                    alt="에듀엣톡 컨설턴트 송민재"
                    style={{
                      width: isMobile ? "90vw" : 585,
                      height: isMobile ? "auto" : 342,
                      marginRight: isMobile ? 0 : 30,
                    }}
                  />
                ) : null}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    marginTop: 30,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: isMobile ? "90vw" : "100%",
                      height: isMobile ? "auto" : 104,
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      borderBottom: "1px solid #DDDDDD",
                      paddingBottom: isMobile ? 20 : 0,
                      marginBottom: 20,
                    }}
                  >
                    {isMobile ? (
                      position > 4392 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                            marginBottom: 5,
                          }}
                        >
                          에듀엣톡 컨설턴트 송민재
                        </TextRightAnimation>
                      ) : null
                    ) : position > 2460 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 20,
                          lineHeight: "32px",
                          color: "#222222",
                          fontWeight: 700,
                          marginBottom: 5,
                        }}
                      >
                        에듀엣톡 컨설턴트 송민재
                      </TextRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 4427 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          학생 스스로 입시에 주도적으로 대응하는 역량을{" "}
                          {isMobile ? <br /> : null}갖도록 돕겠습니다.
                        </TextRightAnimation>
                      ) : null
                    ) : position > 2460 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#555555",
                          fontWeight: 400,
                        }}
                      >
                        학생 스스로 입시에 주도적으로 대응하는 역량을{" "}
                        {isMobile ? <br /> : null}갖도록 돕겠습니다.
                      </TextRightAnimation>
                    ) : null}
                  </div>
                  <ul
                    style={{
                      paddingLeft: isMobile ? 25 : 20,
                      marginBottom: 0,
                    }}
                  >
                    {isMobile ? (
                      position > 4515 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              marginBottom: 5,
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              인문/자연 전 계열 입시컨설팅
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              경력 9년
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 2540 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            marginBottom: 5,
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            인문/자연 전 계열 입시컨설팅
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            경력 9년
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 4546 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              전 메가스터디 러셀 교육평가
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              전임연구원&사업본부
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 2540 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            전 메가스터디 러셀 교육평가
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            전임연구원&사업본부
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}
                  </ul>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 50 : 30,
                  justifyContent: "space-between",
                }}
              >
                {isMobile ? (
                  position > 4697 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_port04_m.png"
                          : "/assets/images/info_port04.png"
                      }
                      alt="에듀엣톡 컨설턴트 김수현"
                      style={{
                        width: isMobile ? "90vw" : 585,
                        height: isMobile ? "auto" : 342,
                        marginRight: isMobile ? 0 : 30,
                      }}
                    />
                  ) : null
                ) : position > 2800 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_port04_m.png"
                        : "/assets/images/info_port04.png"
                    }
                    alt="에듀엣톡 컨설턴트 김수현"
                    style={{
                      width: isMobile ? "90vw" : 585,
                      height: isMobile ? "auto" : 342,
                      marginRight: isMobile ? 0 : 30,
                    }}
                  />
                ) : null}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    marginTop: 30,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: isMobile ? "90vw" : "100%",
                      height: isMobile ? "auto" : 104,
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      borderBottom: "1px solid #DDDDDD",
                      paddingBottom: isMobile ? 20 : 0,
                      marginBottom: 20,
                    }}
                  >
                    {isMobile ? (
                      position > 5000 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                            marginBottom: 5,
                          }}
                        >
                          에듀엣톡 컨설턴트 김수현
                        </TextRightAnimation>
                      ) : null
                    ) : position > 2800 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 20,
                          lineHeight: "32px",
                          color: "#222222",
                          fontWeight: 700,
                          marginBottom: 5,
                        }}
                      >
                        에듀엣톡 컨설턴트 김수현
                      </TextRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 5037 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          원하는 목표를 이루기 위한 입시에 학생들의
                          페이스메이커로서 함께 뛰겠습니다.
                        </TextRightAnimation>
                      ) : null
                    ) : position > 2800 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#555555",
                          fontWeight: 400,
                        }}
                      >
                        원하는 목표를 이루기 위한 입시에 학생들의
                        페이스메이커로서 함께 뛰겠습니다.
                      </TextRightAnimation>
                    ) : null}
                  </div>
                  <ul
                    style={{
                      paddingLeft: isMobile ? 25 : 20,
                      marginBottom: 0,
                    }}
                  >
                    {isMobile ? (
                      position > 5126 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              marginBottom: 5,
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              인문/자연 전 계열 입시컨설팅
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              경력 9년
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 2910 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            marginBottom: 5,
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            인문/자연 전 계열 입시컨설팅
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            경력 9년
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 5150 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              전 기센/ 지트 에듀케이션 과학영역
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              강사
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 2910 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            전 기센/ 지트 에듀케이션 과학영역
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            강사
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}
                  </ul>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 50 : 30,
                  justifyContent: "space-between",
                }}
              >
                {isMobile ? (
                  position > 5299 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_port05_m.png"
                          : "/assets/images/info_port05.png"
                      }
                      alt="에듀엣톡 컨설턴트 진지은"
                      style={{
                        width: isMobile ? "90vw" : 585,
                        height: isMobile ? "auto" : 342,
                        marginRight: isMobile ? 0 : 30,
                      }}
                    />
                  ) : null
                ) : position > 3200 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_port05_m.png"
                        : "/assets/images/info_port05.png"
                    }
                    alt="에듀엣톡 컨설턴트 진지은"
                    style={{
                      width: isMobile ? "90vw" : 585,
                      height: isMobile ? "auto" : 342,
                      marginRight: isMobile ? 0 : 30,
                    }}
                  />
                ) : null}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    marginTop: 30,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: isMobile ? "90vw" : "100%",
                      height: isMobile ? "auto" : 104,
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      borderBottom: "1px solid #DDDDDD",
                      paddingBottom: isMobile ? 20 : 0,
                      marginBottom: 20,
                    }}
                  >
                    {isMobile ? (
                      position > 5610 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                            marginBottom: 5,
                          }}
                        >
                          에듀엣톡 컨설턴트 진지은
                        </TextRightAnimation>
                      ) : null
                    ) : position > 3200 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 20,
                          lineHeight: "32px",
                          color: "#222222",
                          fontWeight: 700,
                          marginBottom: 5,
                        }}
                      >
                        에듀엣톡 컨설턴트 진지은
                      </TextRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 5649 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          세심한 관리, 따뜻한 멘토링, 냉철한 컨설팅,{" "}
                          {isMobile ? <br /> : null}그리고 매력적인 결과
                        </TextRightAnimation>
                      ) : null
                    ) : position > 3200 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#555555",
                          fontWeight: 400,
                        }}
                      >
                        세심한 관리, 따뜻한 멘토링, 냉철한 컨설팅,{" "}
                        {isMobile ? <br /> : null}그리고 매력적인 결과
                      </TextRightAnimation>
                    ) : null}
                  </div>
                  <ul
                    style={{
                      paddingLeft: isMobile ? 25 : 20,
                      marginBottom: 0,
                    }}
                  >
                    {isMobile ? (
                      position > 5736 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              marginBottom: 5,
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              인문/자연 전 계열 입시컨설팅
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              경력 12년
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 3290 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            marginBottom: 5,
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            인문/자연 전 계열 입시컨설팅
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            경력 12년
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 5762 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              전 지트 에듀케이션/사과나무/제이원 수리논술
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              강사
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 3290 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            전 지트 에듀케이션/사과나무/제이원 수리논술
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            강사
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}
                  </ul>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 50 : 30,
                  justifyContent: "space-between",
                }}
              >
                {isMobile ? (
                  position > 5900 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_port06_m.png"
                          : "/assets/images/info_port06.png"
                      }
                      alt="에듀엣톡 컨설턴트 한경태"
                      style={{
                        width: isMobile ? "90vw" : 585,
                        height: isMobile ? "auto" : 342,
                        marginRight: isMobile ? 0 : 30,
                      }}
                    />
                  ) : null
                ) : position > 3600 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_port06_m.png"
                        : "/assets/images/info_port06.png"
                    }
                    alt="에듀엣톡 컨설턴트 한경태"
                    style={{
                      width: isMobile ? "90vw" : 585,
                      height: isMobile ? "auto" : 342,
                      marginRight: isMobile ? 0 : 30,
                    }}
                  />
                ) : null}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    marginTop: 30,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: isMobile ? "90vw" : "100%",
                      height: isMobile ? "auto" : 104,
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      borderBottom: "1px solid #DDDDDD",
                      paddingBottom: isMobile ? 20 : 0,
                      marginBottom: 20,
                    }}
                  >
                    {isMobile ? (
                      position > 6100 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                            marginBottom: 5,
                          }}
                        >
                          에듀엣톡 컨설턴트 한경태
                        </TextRightAnimation>
                      ) : null
                    ) : position > 3600 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 20,
                          lineHeight: "32px",
                          color: "#222222",
                          fontWeight: 700,
                          marginBottom: 5,
                        }}
                      >
                        에듀엣톡 컨설턴트 한경태
                      </TextRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 6100 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          꿈과 끼가 명확한 훌륭한 인재, 그 잠재력을 가진 학생이{" "}
                          <br />
                          바로 여러분입니다. 그 가능성이 뚜렷한 성과로 바뀔수{" "}
                          <br />
                          있도록 저희가 함께 하겠습니다.
                        </TextRightAnimation>
                      ) : null
                    ) : position > 3600 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#555555",
                          fontWeight: 400,
                        }}
                      >
                        꿈과 끼가 명확한 훌륭한 인재, 그 잠재력을 가진 학생이
                        바로 여러분입니다.
                        <br /> 그 가능성이 뚜렷한 성과로 바뀔수 있도록 저희가
                        함께 하겠습니다.
                      </TextRightAnimation>
                    ) : null}
                  </div>
                  <ul
                    style={{
                      paddingLeft: isMobile ? 25 : 20,
                      marginBottom: 0,
                    }}
                  >
                    {isMobile ? (
                      position > 6200 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              marginBottom: 5,
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              인문/자연 전 계열 입시컨설팅
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              경력 9년
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 3650 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            marginBottom: 5,
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            인문/자연 전 계열 입시컨설팅
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            경력 9년
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 6200 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              전 올가교육/파인만(특목자사고)/메가스터디
                              <br />
                              컨설턴트
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 3650 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            전 올가교육/파인만(특목자사고)/메가스터디 컨설턴트
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}
                  </ul>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 50 : 65,
                  justifyContent: "space-between",
                }}
              >
                {isMobile ? (
                  position > 6500 ? (
                    <ImgAnimation
                      src={
                        isMobile
                          ? "/assets/images/info_port07_m.png"
                          : "/assets/images/info_port07.png"
                      }
                      alt="에듀엣톡 컨설턴트 박장호"
                      style={{
                        width: isMobile ? "90vw" : 585,
                        height: isMobile ? "auto" : 342,
                        marginRight: isMobile ? 0 : 30,
                      }}
                    />
                  ) : null
                ) : position > 4000 ? (
                  <ImgAnimation
                    src={
                      isMobile
                        ? "/assets/images/info_port07_m.png"
                        : "/assets/images/info_port07.png"
                    }
                    alt="에듀엣톡 컨설턴트 박장호"
                    style={{
                      width: isMobile ? "90vw" : 585,
                      height: isMobile ? "auto" : 342,
                      marginRight: isMobile ? 0 : 30,
                    }}
                  />
                ) : null}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    marginTop: 30,
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: isMobile ? "90vw" : "100%",
                      height: isMobile ? "auto" : 104,
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      borderBottom: "1px solid #DDDDDD",
                      paddingBottom: isMobile ? 20 : 0,
                      marginBottom: 20,
                    }}
                  >
                    {isMobile ? (
                      position > 6700 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",
                            color: "#222222",
                            fontWeight: 700,
                            marginBottom: 5,
                          }}
                        >
                          에듀엣톡 컨설턴트 박장호
                        </TextRightAnimation>
                      ) : null
                    ) : position > 4000 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 20,
                          lineHeight: "32px",
                          color: "#222222",
                          fontWeight: 700,
                          marginBottom: 5,
                        }}
                      >
                        에듀엣톡 컨설턴트 박장호
                      </TextRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 6700 ? (
                        <TextRightAnimation
                          style={{
                            fontSize: 16,
                            lineHeight: "25.6px",
                            color: "#555555",
                            fontWeight: 400,
                          }}
                        >
                          학생의 3년 학교활동이 향 후 10년을 책임 질수 있습니다.{" "}
                          <br />
                          학생의 미래를 위해 노력하겠습니다.
                        </TextRightAnimation>
                      ) : null
                    ) : position > 4000 ? (
                      <TextRightAnimation
                        style={{
                          fontSize: 16,
                          lineHeight: "25.6px",
                          color: "#555555",
                          fontWeight: 400,
                        }}
                      >
                        학생의 3년 학교활동이 향 후 10년을 책임 질수 있습니다.{" "}
                        <br />
                        학생의 미래를 위해 노력하겠습니다.
                      </TextRightAnimation>
                    ) : null}
                  </div>
                  <ul
                    style={{
                      paddingLeft: isMobile ? 25 : 20,
                      marginBottom: 0,
                    }}
                  >
                    {isMobile ? (
                      position > 6800 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              marginBottom: 5,
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              인문/자연 전 계열 입시컨설팅
                            </PretendardText>

                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                              }}
                            >
                              경력 8년
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 4050 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            marginBottom: 5,
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            인문/자연 전 계열 입시컨설팅
                          </PretendardText>

                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                            }}
                          >
                            경력 8년
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}

                    {isMobile ? (
                      position > 6800 ? (
                        <LiRightAnimation>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                            }}
                          >
                            <PretendardText
                              style={{
                                fontSize: 16,
                                lineHeight: "25.6px",
                                color: "#555555",
                                fontWeight: 400,
                                marginRight: 10,
                              }}
                            >
                              서울대학교사범대학 부설여자중 외 5곳, <br />{" "}
                              경문고 외 2곳 진로강사
                            </PretendardText>
                          </div>
                        </LiRightAnimation>
                      ) : null
                    ) : position > 4050 ? (
                      <LiRightAnimation>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                          }}
                        >
                          <PretendardText
                            style={{
                              fontSize: 16,
                              lineHeight: "25.6px",
                              color: "#555555",
                              fontWeight: 400,
                              marginRight: 10,
                            }}
                          >
                            서울대학교사범대학 부설여자중 외 5곳, 경문고 외 2곳
                            진로강사
                          </PretendardText>
                        </div>
                      </LiRightAnimation>
                    ) : null}
                  </ul>
                </div>
              </div>
              {/* ----------------------------- */}

              <div
                style={{
                  border: "1px solid #DDDDDD",
                }}
              ></div>
              <PretendardText
                style={{
                  marginTop: isMobile ? "40px" : "56px",
                  color: "#286EF1",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "25.6px",
                  textAlign: "center",
                  textDecorationLine: "underline",
                  textDecorationColor: "#E8F3FF",
                  textDecorationThickness: "6px",
                }}
              >
                + 이외 다른 컨설턴트들이 기다리고 있습니다.
              </PretendardText>
            </div>
          </MainContainer>
        </MainLayout>
        <Footer />

        <FooterBtnGroup />
      </MainColorLayout>
    </>
  );
}

export default EduatalkIntro;

const ImgAnimation = styled.img`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInUp 1s;
`;

const ImgLeftAnimation = styled.img`
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  animation: fadeInLeft 1s;
`;

const ImgRightAnimation = styled.img`
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInRight 1s;
`;

const TextUPAnimation = styled(PretendardText)`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInUp 1s;
`;

const TextLeftAnimation = styled(PretendardText)`
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  animation: fadeInLeft 1s;
`;

const TextRightAnimation = styled(PretendardText)`
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInRight 1s;
`;

const LiLeftAnimation = styled.li`
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  animation: fadeInLeft 1s;
`;

const LiRightAnimation = styled.li`
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  animation: fadeInRight 1s;
`;
