import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import PretendardText from "../../atoms/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import MainBtn from "../../atoms/button/mainBtn";
import TextBtn from "../../atoms/button/textBtn";

const PopupModal = ({ setVisible, data, btnOnPress, btnConfirmPress }) => {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const history = useHistory();

  return (
    <ModalWrapper>
      <ModalPosition isMobile={isMobile}>
        <ModalContain isMobile={isMobile}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={data.fileUrl}
              style={{
                width: "100%",
                height: "100%",
                // cursor: "pointer",
              }}
              alt=""
              // onClick={() => {
              //   window.open(data.link, "_parent");
              // }}
            />

            <div
              style={{
                marginTop: 18,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MainBtn
                style={{
                  background: "#00B3CE",
                }}
                onClick={() => {
                  btnConfirmPress();
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  확인
                </PretendardText>
              </MainBtn>
            </div>

            <div
              style={{
                marginTop: 18,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextBtn
                onClick={() => {
                  btnOnPress();
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    fontWeight: 400,
                    textAlign: "center",
                    textDecoration: "underline",
                  }}
                >
                  오늘 하루 그만보기
                </PretendardText>
              </TextBtn>
            </div>
          </div>
        </ModalContain>
      </ModalPosition>
    </ModalWrapper>
  );
};

export default PopupModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(2px);

  overflow: hidden;
  touch-action: none;
`;

const ModalPosition = styled.div.attrs((props) => {})`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
`;

const ModalContain = styled.div.attrs((props) => {})`
  width: ${(props) => (props.isMobile ? "80vw" : "500px")};
  height: auto;
  padding: 30px 20px 20px 20px;
  box-shadow: 0 0 8.5px 1.5px rgba(165, 161, 155, 0.1);
  background-color: #fff;
  border-radius: 10px;

  overflow: auto;
`;
