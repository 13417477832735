import api from "./api.js";

export default class TermsApi { 
  static async Get(data) {
    return await api.get(`web/terms`, data);
  }
  static async GetById(termsId) {
    return await api.get(`web/terms/${termsId}`);
  }  
}
