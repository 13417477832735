import useDisplay from "../../../hooks/useDisplay";

function MainBGLayout02(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: isMobile ? "100%" : "100%",
          backgroundColor: "#1E1E1E",
          minHeight: isMobile ? 10 : 1555,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center bottom",
          backgroundImage: isMobile
            ? "url(/assets/images/highEntrance_vector_m.png)"
            : "url(/assets/images/highEntrance_vector.png)",
          backgroundSize: isMobile ? "100% 82%" : "1142px 1221.5px",

          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default MainBGLayout02;
