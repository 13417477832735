import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PretendardText from "../pretendardText";
import { route } from "../../../routes/route";

function MainLinkBtn({ style, text, path, isWhite }) {
  const history = useHistory();
  const [hover, setHover] = useState(false);

  return (
    <>
      {isWhite ? (
        <div
          onMouseEnter={() => setHover(true)} // 마우스엔터(호버)시 키값이 저장된다
          onMouseLeave={() => setHover(false)} // 마우스리브 시에는 키값이 지워진다
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "12px 0px",
            borderBottom: hover ? "2px solid #00B3CE" : "2px solid #DDDDDD",
            cursor: "pointer",
            ...style,
          }}
          onClick={() => {
            history.push({
              pathname: path,
            });
          }}
        >
          <PretendardText
            style={{
              fontSize: 16,
              lineHeight: "25.6px",
              color: hover ? "#00B3CE" : "#ffffff",
              fontWeight: 700,
              width: 130,
            }}
          >
            {text}
          </PretendardText>
          <img
            src={
              hover
                ? "/assets/icons/ic_arrow-right-primary.svg"
                : "/assets/icons/ic_arrow-right_white.png"
            }
            style={{
              width: 20,
              height: 20,
            }}
            alt="Arrow Right Icon"
          />
        </div>
      ) : (
        <div
          onMouseEnter={() => setHover(true)} // 마우스엔터(호버)시 키값이 저장된다
          onMouseLeave={() => setHover(false)} // 마우스리브 시에는 키값이 지워진다
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "12px 0px",
            borderBottom: hover ? "2px solid #00B3CE" : "2px solid #353535",
            cursor: "pointer",
            ...style,
          }}
          onClick={() => {
            history.push({
              pathname: path,
            });
          }}
        >
          <PretendardText
            style={{
              fontSize: 16,
              lineHeight: "25.6px",
              color: hover ? "#00B3CE" : "#222222",
              fontWeight: 700,
              width: 130,
            }}
          >
            {text}
          </PretendardText>
          <img
            src={
              hover
                ? "/assets/icons/ic_arrow-right-primary.svg"
                : "/assets/icons/ic_arrow-right.png"
            }
            style={{
              width: 20,
              height: 20,
            }}
            alt="Arrow Right Icon"
          />
        </div>
      )}
    </>
  );
}

export default MainLinkBtn;
