import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HeaderM from "../components/organisms/headerM";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import PretendardText from "../components/atoms/pretendardText";
import MainBtn from "../components/atoms/button/mainBtn";
import ConfirmModal from "../components/templates/modals/confirmModal";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import Input from "../components/atoms/input/input";
import MainLayout from "../components/atoms/layouts/mainLayout";
import { route } from "../routes/route";
import MainContainer from "../components/atoms/layouts/mainContainer";
import useDisplay from "../hooks/useDisplay";
import ReviewApi from "../api/review-api";
import moment from "moment";
import Pagenation from "../components/molecules/pagenation";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";

function ReviewStudent(props) {
  const [selected, setSelected] = useState(0);

  const handleMouseOver = (key) => {
    setSelected(key);
  };

  const handleMouseOut = () => {
    setSelected(0);
  };

  const history = useHistory();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  // const reviews = [{title : "title1", key : 1}, {title : "title2", key : 2}, {title : "title3", key : 3}, {title : "title4", key : 4}];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const [isT, setIsT] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setPagination({
        ...pagination,
        size: 3,
        rangeSize: 5,
      });
      setIsT(false);
    } else {
      setPagination({
        ...pagination,
        size: 6,
        rangeSize: 10,
      });

      setIsT(true);
    }
  }, [isMobile]);

  const { page } = useParams();
  const [pagination, setPagination] = useState({
    size: 6, // 한 페이지에 표시될 게시글 수
    rangeSize: 10, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [reviewType, setReviewType] = useState("TEXT");
  const [isInputS, setIsInputS] = useState(false);

  const getList = async () => {
    try {
      const data = {
        reviewType: reviewType,
        keyword: keyword,
        page: page - 1,
        size: pagination.size,
      };

      let list = (await ReviewApi.Get(data)).data.data;
      setList(
        list.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: list.totalElements,
        totalPages: list.totalPages,
        first: list.first,
        last: list.last,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getList();
  }, [location, isT]);

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 100,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  후기
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: isMobile ? "start" : "space-between",
                  alignItems: isMobile ? "start" : "center",
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 32 : 60,
                    lineHeight: isMobile ? "44.8px" : "84px",
                    color: "#222222",
                    fontWeight: 700,
                    marginBottom: isMobile ? 50 : 0,
                  }}
                >
                  빛나는 후기
                </PretendardText>

                <div
                  style={{
                    width: isMobile ? "90vw" : 342,
                    height: 50,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderBottom: isInputS
                      ? "1px solid #00B3CE"
                      : "1px solid #353535",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      placeholder="Search"
                      style={{
                        width: "100%",
                        border: "none",
                        fontSize: 16,
                        lineHeight: "25.6px",
                        color: "#222222",
                        fontWeight: 400,
                        outline: "none",
                      }}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          getList();
                        }
                      }}
                      onFocus={() => {
                        setIsInputS(true);
                      }}
                      onBlur={() => {
                        setIsInputS(false);
                      }}
                    />
                    {isInputS ? (
                      <img
                        src="/assets/icons/ic_search_f.png"
                        style={{
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          getList();
                        }}
                        alt="Search Icon"
                      />
                    ) : (
                      <img
                        src="/assets/icons/ic_search.png"
                        style={{
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          getList();
                        }}
                        alt="Search Icon"
                      />
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  position: "relative",
                }}
              >
                <div
                  style={{
                    height: 1,
                    backgroundColor: "#DDDDDD",
                    position: "absolute",
                    top: isMobile ? 59 : 83,
                    left: isMobile ? 0 : 610,
                    width: isMobile ? "45vw" : 590,
                  }}
                />

                <div
                  style={{
                    height: 1,
                    display: isMobile ? "none" : "inline",
                    backgroundColor: "#DDDDDD",
                    position: "absolute",
                    top: 83,
                    left: 0,
                    width: 305,
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: isMobile ? "45vw" : 305,
                      height: isMobile ? 60 : 84,
                      backgroundColor: "#F7F7F7",
                      borderBottom: "none",
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                      alignContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push({
                        pathname: route.review_brilliant + `/1`,
                      });
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 20,
                        lineHeight: "32px",
                        color: "#9B9B9B",
                        fontWeight: 400,
                      }}
                    >
                      영상
                    </PretendardText>
                  </div>
                  <div
                    style={{
                      width: isMobile ? "45vw" : 305,
                      height: isMobile ? 60 : 84,
                      border: "1px solid #DDDDDD",
                      borderBottom: "none",
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                      alignContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: 20,
                        lineHeight: "32px",
                        color: "#00B3CE",
                        fontWeight: 400,
                      }}
                    >
                      수필
                    </PretendardText>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  width: isMobile ? "90vw" : "100%",
                  gridTemplateRows: "1fr ",
                  gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr",
                  marginTop: 30,
                  marginBottom: 30,
                  gridRowGap: 23,
                  gridColumnGap: 23,
                }}
              >
                {list.map(function (review) {
                  return (
                    <div
                      key={review.id}
                      style={{
                        width: isMobile ? "89.5vw" : 385,
                        height: isMobile ? "89.5vw" : 385,
                        display: "flex",
                        backgroundColor:
                          review.id === selected ? "#00B3CE" : "#F7F7F7",
                        boxShadow:
                          review.id === selected
                            ? "4px 5px 13px 0px #00000040"
                            : "none",
                        borderRadius: 20,
                        paddingTop: isMobile ? 30 : 50,
                        paddingBottom: isMobile ? 30 : 50,
                        paddingRight: isMobile ? 20 : 30,
                        paddingLeft: isMobile ? 20 : 30,
                        flexDirection: "column",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => {
                        setSelected(review.id);
                      }}
                      onMouseLeave={() => setSelected(0)}
                      onClick={() => {
                        history.push({
                          pathname:
                            route.review_student_detail + `/${review.id}`,
                        });
                      }}
                    >
                      <div>
                        <PretendardText
                          style={{
                            fontSize: 20,
                            lineHeight: "32px",

                            color:
                              review.id === selected ? "#FFFFFF" : "#222222",
                            fontWeight: 700,
                            width: "100%",
                            marginBottom: 10,
                            maxHeight: 64,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            display: "-webkit-box",
                          }}
                        >
                          {review.title}
                        </PretendardText>

                        <PretendardText
                          style={{
                            fontSize: 16,
                            lineHeight: "16px",
                            color:
                              review.id === selected ? "#FFFFFF" : "#555555",
                            fontWeight: 400,
                            height: isMobile ? "100%" : 187,
                          }}
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              WebkitLineClamp: "3",
                              WebkitBoxOrient: "vertical",
                              display: "-webkit-box",
                            }}
                          >
                            {review.content}
                          </div>
                        </PretendardText>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <PretendardText
                          style={{
                            fontSize: 14,
                            lineHeight: "22.4px",
                            color:
                              review.id === selected ? "#FFFFFF" : "#9B9B9B",
                            fontWeight: 400,
                            width: "100%",
                          }}
                        >
                          {moment(review.createdAt).format("YYYY.MM.DD")}
                        </PretendardText>
                        <img
                          src={
                            review.id === selected
                              ? "/assets/icons/ic_arrow-right_white.png"
                              : "/assets/icons/ic_arrow-right.png"
                          }
                          alt="Arrow Right Icon"
                          style={{
                            width: 24,
                            height: 24,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            history.push({
                              pathname:
                                route.review_student_detail + `/${review.id}`,
                            });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagenation
                  route={route.review_student}
                  currentPage={page}
                  totalPages={pagination.totalPages}
                  rangeSize={pagination.rangeSize}
                  first={pagination.first}
                  last={pagination.last}
                />
              </div>
            </div>
          </MainContainer>
        </MainLayout>

        <Footer />

        <FooterBtnGroup />
      </MainColorLayout>
    </>
  );
}

export default ReviewStudent;
