import styled from "styled-components";

function MainColorLayout(props) {
  return (
    <>
      <ScrollYContain
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </ScrollYContain>
    </>
  );
}

export default MainColorLayout;

const ScrollYContain = styled.div.attrs((props) => {})`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;
