import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { route } from "../routes/route";

import PretendardText from "../components/atoms/pretendardText";
import MainColorLayout from "../components/atoms/layouts/mainColorLayout";
import MainLayout from "../components/atoms/layouts/mainLayout";
import MainContainer from "../components/atoms/layouts/mainContainer";
import InquiryBGLayout from "../components/atoms/layouts/inquiryBGLayout";

import ConfirmCheckModal from "../components/templates/modals/confirmCheckModal";

import HeaderM from "../components/organisms/headerM";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import FooterBtnGroup from "../components/organisms/footerBtnGroup";
import InquiryItem from "../components/organisms/inquiryItem";

import useDisplay from "../hooks/useDisplay";
import AskApi from "../api/ask-api";

function Inquiry(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const history = useHistory();

  const packageProgram = [
    {
      title: "중3 2학기",
      content: "Pre 생기부레벨업",
      money: "100만원",
      key: "COURSE7",
      note: "※ 진로적성 포함",
      mNote: "※ 진로적성 포함",
    },
    {
      title: "고1~고3",
      content: "Pro 생기부레벨업",
      money: "180만원",
      key: "COURSE8",
      note: "※ 진로적성/생기부진단&설문포함",
      mNote: "※진로적성/생기부진단<br/>&설문포함",
    },
    {
      title: "고1~고3",
      content: "Master 생기부레벨업",
      money: "300만원",
      key: "COURSE9",
      note: "※ 진로적성/생기부진단&설문/2in1포함",
      mNote: "※ 진로적성/생기부진단&<br/>설문/2in1포함",
    },
  ];

  const basicProgram = [
    {
      title: "중3",
      content: "진로적성검사 Ⅰ",
      money: "30만원",
      key: "COURSE10",
      note: "",
      mNote: "",
    },
    {
      title: "고1",
      content: "진로적성검사 Ⅱ",
      money: "40만원",
      key: "COURSE11",
      note: "※ 고1, 5월까지 신청가능",
      mNote: "※ 고1, 5월까지 신청가능",
    },
    {
      title: "고1, 고2",
      content: "체험수업 1회 생기부레벨업",
      money: "40만원",
      key: "COURSE12",
      note: "※ 7일 내 학기제 신청시, 공제 후 차액결제",
      mNote: "※ 7일 내 학기제 신청시,<br/>공제 후 차액결제",
    },
    {
      title: "고2, 고3",
      content: "생기부 진단 컨설팅",
      money: "50만원",
      key: "COURSE13",
      note: "※ 7일 내 학기제 신청시, 공제 후 차액결제",
      mNote: "※ 7일 내 학기제 신청시,<br/>공제 후 차액결제",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [modalOpen, setModalOpen] = useState(false);

  const [saveData, setSaveData] = useState({
    inquiryType: "",
    name: "",
    phoneNumber: "",
    content: "",
  });
  const [agree1, setAgree1] = useState(false);

  const saveFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("inquiryType", saveData.inquiryType);
      formData.append("name", saveData.name);
      formData.append("phoneNumber", saveData.phoneNumber);
      formData.append("content", saveData.content);

      await AskApi.Create(formData);
      toast("문의되었습니다.");
      setModalOpen(false);

      setSaveData({
        inquiryType: "",
        name: "",
        phoneNumber: "",
        content: "",
      });
      setAgree1(false);
    } catch (error) {
      toast("잠시후 다시 시도해주세요");
    }
  };

  return (
    <>
      <MainColorLayout>
        {isMobile ? <HeaderM main={false} /> : <Header main={false} />}

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 30,
                paddingTop: isMobile ? 20 : 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                  }}
                >
                  홈
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#555555",
                    fontWeight: 400,
                    margin: "0px 15px",
                  }}
                >
                  ·
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 14,
                    lineHeight: "22.4px",
                    color: "#00B3CE",
                    fontWeight: 400,
                  }}
                >
                  문의하기
                </PretendardText>
              </div>

              <PretendardText
                style={{
                  fontSize: isMobile ? 32 : 60,
                  lineHeight: isMobile ? "44.8px" : "84px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: isMobile ? 50 : 100,
                  marginTop: isMobile ? 20 : 30,
                }}
              >
                문의하기
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 10,
                }}
              >
                담당 컨설턴트 배정을 위하여 상담{isMobile ? <br /> : null} 필수
                정보 제출이 필요합니다.
              </PretendardText>

              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "25.6px",
                  color: "#555555",
                  fontWeight: 400,
                  marginBottom: 30,
                }}
              >
                유선 상담 진행 후 결제가 완료되면 정규 프로그램이
                {isMobile ? <br /> : null} 시작됩니다.
              </PretendardText>
            </div>
          </MainContainer>
        </MainLayout>

        <InquiryBGLayout>
          <div
            style={{
              width: isMobile ? "90vw" : 1200,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PretendardText
              style={{
                fontSize: 24,
                lineHeight: "38.4px",
                color: "#FFFFFF",
                fontWeight: 700,
                marginTop: 100,
                marginBottom: 30,
              }}
            >
              문의 주실 서비스 유형은 무엇인가요?
            </PretendardText>

            <PretendardText
              style={{
                fontSize: 18,
                lineHeight: "28.8px",
                color: "#FFFFFF",
                fontWeight: 700,
                marginBottom: isMobile ? 12 : 26,
                textAlign: "center",
              }}
            >
              학기제 - 패키지 프로그램
            </PretendardText>

            <div
              style={{
                display: "grid",
                width: isMobile ? "90vw" : "100%",
                gridTemplateRows: "1fr ",
                gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                gridColumnGap: isMobile ? 15 : 23,
                gridRowGap: isMobile ? "4.5vw" : 30,
                paddingBottom: isMobile ? 100 : 0,
              }}
            >
              {packageProgram.map(function (service) {
                return (
                  <InquiryItem
                    key={service.key}
                    setSaveData={setSaveData}
                    saveData={saveData}
                    service={service}
                  />
                );
              })}
            </div>

            <PretendardText
              style={{
                fontSize: 12,
                lineHeight: "19.2px",
                color: "#FF6767",
                fontWeight: 500,
                marginBottom: isMobile ? 12 : 28,
                marginTop: 11,
                textAlign: isMobile ? "left" : "center",
              }}
            >
              ※ 매학기 연장시 할인 혜택
            </PretendardText>

            <div
              style={{
                width: isMobile ? "90vw" : 1200,
                display: "flex",
                flexDirection: "column",
                marginBottom: 50,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 18,
                  lineHeight: "28.8px",
                  color: "#ffffff",
                  fontWeight: 700,
                  marginBottom: isMobile ? 12 : 26,
                  textAlign: "center",
                }}
              >
                기본 프로그램
              </PretendardText>
              <div
                style={{
                  display: "grid",
                  width: isMobile ? "90vw" : "100%",
                  gridTemplateRows: "1fr ",
                  gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                  gridColumnGap: isMobile ? 15 : 23,
                  gridRowGap: isMobile ? "4.5vw" : 30,
                }}
              >
                {basicProgram.map(function (service) {
                  return (
                    <InquiryItem
                      key={service.key}
                      setSaveData={setSaveData}
                      saveData={saveData}
                      service={service}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </InquiryBGLayout>

        <MainLayout>
          <MainContainer>
            <div
              style={{
                flex: 1,
                paddingBottom: 100,
                paddingTop: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 24,
                  lineHeight: "38.4px",
                  color: "#222222",
                  fontWeight: 700,
                  marginBottom: 30,
                }}
              >
                에듀엣톡에 문의하시는{isMobile ? <br /> : null} 정보를 입력해
                주세요.
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#222222",
                    fontWeight: 700,
                  }}
                >
                  이름
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#00B3CE",
                    fontWeight: 700,
                  }}
                >
                  *
                </PretendardText>
              </div>

              <div
                style={{
                  height: 50,
                  display: "flex",
                  borderBottom: "1px solid #DDDDDD",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <input
                  placeholder="이름을 입력해 주세요."
                  style={{
                    width: "100%",
                    border: "none",
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#191F28",
                    fontWeight: 400,
                    outline: "none",
                  }}
                  value={saveData.name}
                  onChange={(e) => {
                    setSaveData({ ...saveData, name: e.target.value });
                  }}
                ></input>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                  marginTop: 30,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#222222",
                    fontWeight: 700,
                  }}
                >
                  연락처
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#00B3CE",
                    fontWeight: 700,
                  }}
                >
                  *
                </PretendardText>
              </div>

              <div
                style={{
                  height: 50,
                  display: "flex",
                  borderBottom: "1px solid #DDDDDD",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <input
                  placeholder="숫자만 입력해주세요."
                  style={{
                    width: "100%",
                    border: "none",
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#191F28",
                    fontWeight: 400,
                    outline: "none",
                  }}
                  value={saveData.phoneNumber}
                  onChange={(e) => {
                    setSaveData({ ...saveData, phoneNumber: e.target.value });
                  }}
                ></input>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                  marginTop: 30,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#222222",
                    fontWeight: 700,
                  }}
                >
                  상담 내용을 입력해 주세요
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#00B3CE",
                    fontWeight: 700,
                  }}
                >
                  *
                </PretendardText>
              </div>
              <div
                style={{
                  height: 50,
                  display: "flex",
                  border: "1px solid #DDDDDD",
                  borderRadius: 20,
                  height: 260,
                  padding: 20,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <textarea
                  placeholder="정확한 상담을 위하여 현재 상담받고 싶은 부분이나 기타 궁금한 사항을 적어주세요."
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#191F28",
                    fontWeight: 400,
                    outline: "none",
                  }}
                  value={saveData.content}
                  onChange={(e) => {
                    setSaveData({ ...saveData, content: e.target.value });
                  }}
                ></textarea>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 30,
                }}
              >
                <img
                  src={
                    agree1
                      ? "/assets/icons/check_on.png"
                      : "/assets/icons/check_off.png"
                  }
                  alt={agree1 ? "Checked Icon" : "Unchecked Icon"}
                  style={{
                    width: 25,
                    height: 25,
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={() => {
                    setAgree1(!agree1);
                  }}
                />

                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "222222",
                    fontWeight: 400,
                    marginRight: 10,
                  }}
                >
                  [필수] 개인정보 수집/이용에 동의합니다.
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "25.6px",
                    color: "#0036AF",
                    fontWeight: 400,
                    marginRight: 10,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(route.policy);
                  }}
                >
                  내용보기
                </PretendardText>
              </div>

              <div
                style={{
                  display: "flex",
                  width: isMobile ? "90vw" : "100%",
                  height: 110,
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: 150,
                    height: 50,
                    flexDirection: "row",
                    borderBottom: "2px solid #353535",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!saveData.inquiryType) {
                      return toast("서비스유형을 선택해주세요");
                    }
                    if (!saveData.name) {
                      return toast("이름을 입력해주세요");
                    }
                    if (!saveData.phoneNumber) {
                      return toast("연락처를 입력해주세요");
                    }
                    if (!agree1) {
                      return toast("필수동의항목에 동의해주세요.");
                    }
                    setModalOpen(true);
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "25.6px",
                      color: "#222222",
                      fontWeight: 700,
                    }}
                  >
                    문의하기
                  </PretendardText>

                  <img
                    src="/assets//icons/ic_send.png"
                    alt="Send Icon"
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </div>
              </div>
            </div>
          </MainContainer>
        </MainLayout>
        <Footer />

        <FooterBtnGroup />
      </MainColorLayout>
      {modalOpen ? (
        <ConfirmCheckModal
          content={"문의를 제출하시겠습니까?"}
          onBtnText={"문의하기"}
          onCancelBtnText={"닫기"}
          onCancelBtnClick={() => {
            setModalOpen(false);
          }}
          onBtnClick={() => {
            saveFunc();
          }}
        ></ConfirmCheckModal>
      ) : null}
    </>
  );
}

export default Inquiry;
