import useDisplay from "../../../hooks/useDisplay";

function MainBGLayout02(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: isMobile ? "100%" : "100%",
          minHeight: isMobile ? 500 : 300,
          backgroundImage: isMobile ? "url(/assets/images/info_bg02_m.png)" : "url(/assets/images/info_bg02.png)",
          backgroundSize: "100% 100%",

          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default MainBGLayout02;
