import useDisplay from "../../../hooks/useDisplay";

function MainBGLayout02(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: isMobile ? "100%" : "100%",
          minHeight: isMobile ? 800 : 630,
          backgroundImage: isMobile
            ? "url(/assets/images/programIntro_bg01_m.png)"
            : "url(/assets/images/programIntro_bg01.png)",
          backgroundSize: "100% 100%",

          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default MainBGLayout02;
