const prefix = "/eduatalk";

export const route = {
  main: prefix + "/main",

  eduatalk_intro: prefix + "/eduatalk_intro",
  program_intro: prefix + "/program_intro",
  career_test: prefix + "/career_test",
  high_entrance: prefix + "/high_entrance",
  college_entrance: prefix + "/college_entrance",
  review_brilliant: prefix + "/review_brilliant",
  review_student: prefix + "/review_student",
  review_student_detail: prefix + "/review_student_detail",
  inquiry: prefix + "/inquiry",
  service_payment: prefix + "/service_payment",

  term: prefix + "/term",
  policy: prefix + "/policy",
  refund: prefix + "/refund",
  payment_fail: prefix + "/payment_fail",
  payment_success: prefix + "/payment_success",
};
