import React, { useState, useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { BrowserRouter } from "react-router-dom";

import Router from "./routes/router";
import CommonApi from "./api/common-api";

function App() {

  // 방문자 등록
  let visitFlag = false;
  const visitFunc = async () => {
    try {
      if (visitFlag) {
        return;
      }
      await CommonApi.createVisit();
      visitFlag = true;
    } catch (error) { }
  };

  useEffect(() => {
    visitFunc();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Router />
      </BrowserRouter>

      <ToastContainer
        className="toast"
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
      />
    </div>
  );
}

export default App;
