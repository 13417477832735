import useDisplay from "../../../hooks/useDisplay";

function MainContainer(props) {
  const { isMobile, isTablet, isDesktop } = useDisplay();

  return (
    <>
      <div
        style={{
          width: isMobile ? "100%" : 1200,
          display: "flex",
          ...props.style,
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default MainContainer;
