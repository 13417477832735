import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import PretendardText from "../../atoms/pretendardText";
import useDisplay from "../../../hooks/useDisplay";
import MainBtn from "../../atoms/button/mainBtn";
import MainBtnNotDisabled from "../../atoms/button/mainBtnNotDisabled";
// import GrayBtn from "../../atoms/button/grayBtn";

const ConfirmModal = ({ content, contentStyle, onBtnClick, onBtnText }) => {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <ModalWrapper>
      <ModalPosition>
        <ModalContain>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {content ? (
              <PretendardText
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  lineHeight: "25px",
                  color: "#FFFFFF",
                  fontWeight: 400,
                  ...contentStyle,
                }}
              >
                {content}
              </PretendardText>
            ) : null}

            <div
              style={{
                marginTop: 25,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MainBtnNotDisabled
                onClick={() => {
                  onBtnClick();
                }}
                style={{
                  background: "#7E53FF",
                  width: 58,
                  height: 39,
                  borderRadius: 4,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 15,
                    lineHeight: "20.3px",
                    color: "white",
                    fontWeight: 700,
                  }}
                >
                  {onBtnText}
                </PretendardText>
              </MainBtnNotDisabled>
            </div>
          </div>
        </ModalContain>
      </ModalPosition>
    </ModalWrapper>
  );
};

export default ConfirmModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background: rgba(40, 40, 40, 0.5);
  backdrop-filter: blur(2px);

  overflow: hidden;
  touch-action: none;
`;

const ModalPosition = styled.div.attrs((props) => {})`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
`;

const ModalContain = styled.div.attrs((props) => {})`
  width: "360px"
  height: auto;
  padding: 40px 60px 40px 60px;
  background-color: #202026;
  border-radius: 4px;
  border: 1px solid #424249;

  overflow: auto;
`;
